import { css } from 'styled-components';
import { colors } from '../../../style-utilities/variables';
export default css`
  img {
    width: 3em;
    border-radius: 50%;

    margin-right: 0.4rem;
  }
  span {
    font-size: 18px;
  }
  #user-widget-btn:hover {
    background: ${colors.primary};
    color: #fff;
    box-shadow: 0px 0px 0px;
  }
`;
