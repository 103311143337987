import { get, post, doDelete } from '../utilities/fetchUtils';

export default class ReviewsService {
  API_URL = `${process.env.RIVIANA_BACKEND_URI}/api/reviews`;
  ACTIVE_BRAND = process.env.ACTIVE_BRAND;

  getReview = async (user, type, slug) => {
    const brand = this.ACTIVE_BRAND;
    const userId = user.id;
    const id = `${brand}_${type}_${userId}_${slug}`;
    const url = `${this.API_URL}/${id}`;

    return get(url);
  };

  getReviewsForItem = async (type, slug) => {
    const brand = this.ACTIVE_BRAND;
    const brandTypeItem = `${brand}_${type}_${slug}`;
    const url = `${this.API_URL}/item/${brandTypeItem}`;

    return get(url);
  };

  getReviewsCountForItem = async (type, slug) => {
    const brand = this.ACTIVE_BRAND;
    const brandTypeItem = `${brand}_${type}_${slug}`;
    const url = `${this.API_URL}/count/${brandTypeItem}`;

    return get(url);
  };

  createReview = async (user, type, slug, item) => {
    const brand = this.ACTIVE_BRAND;
    const userId = user.id;

    const payload = {
      brand,
      type,
      userId,
      slug,
      stars: item.stars || 0,
      title: item.title || '',
      text: item.text || '',
      hideProfileImage: item.hideProfileImage || false,
      userName:
        user.first_name && user.last_name
          ? user.first_name + ' ' + user.last_name
          : '',
      userAvatar:
        user.avatar_urls && user.avatar_urls[96] ? user.avatar_urls[96] : ''
    };

    return post(this.API_URL, payload);
  };

  deleteReview = async (user, type, slug) => {
    const brand = this.ACTIVE_BRAND;
    const userId = user.id;
    const id = `${brand}_${type}_${userId}_${slug}`;

    return doDelete(id);
  };
}
