import React, { Component } from 'react';
import styled from 'styled-components';
import ComponentStyles from '../../brand-styles/components/Header/Header.styles';
// import components
import MainLogo from './MainLogo';
import MenuToggle from './MenuToggle';
import NavigationMenu from './NavigationMenu';
import Search from './Search';
import { breakpoints, colors } from '../../style-utilities/variables';
import WhereToBuy from './WhereToBuyNav';

const Div = styled.div`
  position: relative;
  top: 0px;
  width: 100%;
  z-index: 500;
  height: 105px;
  @media (min-width: ${breakpoints.tablet}) {
    height: 65px;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    height: 0px;
  }

  .top-header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2;
    background-color: ${colors.primary};

    .header-container {
      height: 65px;
      margin: 0 auto;
      button {
        outline: none;
      }
      .mobile-nav {
        padding-right: 10px;
        display: flex;
        justify-content: space-between;
        a {
          width: 120px;
          top: 0;
        }
        .search-burger {
          display: flex;
          height: 65px;
        }
      }
      .mobile-nav svg {
        color: white;
        outline: none;
        cursor: pointer;
      }
    }
  }
  .styled-where-to-buy {
    a.where-to-buy-link {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 12px 20px 10px;
    }
    width: 100%;
    background: #f1f1f1;
  }

  @media (min-width: 900px) {
    .top-header .header-container .mobile-nav,
    .styled-where-to-buy {
      display: none;
    }
  }

  ${ComponentStyles}
`;

class Header extends Component {
  state = {
    openMenu: false
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.userContext.authenticatedUser !==
      prevProps.userContext.authenticatedUser
    ) {
      this.setState({
        openMenu: !this.state.openMenu
      });
    }
  }

  menuToggle = () => this.setState({ openMenu: !this.state.openMenu });

  //ON MOBILE: Close Menu When Search Button is Clicked Function
  closeMenuOnSearch = () => this.setState({ openMenu: false });

  render() {
    return (
      <Div>
        <div className="top-header">
          <div className="header-container">
            <NavigationMenu
              openMenu={this.state.openMenu}
              toggle={this.menuToggle}
              siteLogo={this.props.siteLogo}
              siteType={this.props.siteType}
              altText={this.props.altText}
              siteTitle={this.props.siteTitle}
              brandTitle={this.props.brandTitle}
            />
            <div className="mobile-nav">
              <div className="mobile-logo">
                <MainLogo
                  siteLogo={this.props.siteLogo}
                  altText={this.props.altText}
                  closeMenuOnSearch={this.closeMenuOnSearch}
                />
              </div>
              <div className="search-burger">
                <Search
                  closeMenuOnSearch={this.closeMenuOnSearch}
                  siteTitle={this.props.siteTitle}
                />
                <MenuToggle toggleMobile={this.menuToggle} />
              </div>
            </div>
          </div>
          <div className="styled-where-to-buy">
            <WhereToBuy />
          </div>
        </div>
      </Div>
    );
  }
}

export default Header;
