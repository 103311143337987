import React from 'react';
import styled from 'styled-components';

import { breakpoints, colors } from '../../style-utilities/variables';
import UtilityLink from './UtilityLink';
import CopyRight from './CopyRight';
import ComponentStyles from '../../brand-styles/components/Footer/BottomFooter.styles';

const Div = styled.div`
  background-color: ${colors.secondary};
  padding: 20px;
  a {
    color: white;
    text-decoration: none;
  }
  ul {
    margin: 0;
    padding: 0;
  }
  @media (min-width: ${breakpoints.tablet}) {
    padding: 0 30px 0 15px;
  }
  .BottomFootContainer {
    max-width: ${breakpoints.maxContainer};
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    grid-template-columns: 3, 1fr;
    grid-template-rows: auto auto auto;
    grid-template-areas:
      'UtilityLink UtilityLink UtilityLink'
      'CopyRight CopyRight CopyRight'
      'CopyRight CopyRight CopyRight';
    align-items: center;
    justify-items: center;

    @media (min-width: ${breakpoints.tablet}) {
      justify-items: left;
      grid-template-areas: 'UtilityLink UtilityLink UtilityLink UtilityLink UtilityLink . . . . CopyRight CopyRight CopyRight';
    }
  }

  /* Dynamic brand styles */
  ${ComponentStyles}
`;

const BottomFooter = ({ siteTitle }) => {
  return (
    <Div>
      <div className="BottomFootContainer">
        <UtilityLink siteTitle={siteTitle} />
        <CopyRight />
      </div>
    </Div>
  );
};

export default BottomFooter;
