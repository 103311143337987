import React, { Component } from 'react';
import { navigate } from '@reach/router';
import styled from 'styled-components';
import { StaticQuery, graphql, Link } from 'gatsby';
import classNames from 'classnames';
import ReactHtmlParser from 'react-html-parser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeft,
  faMinus,
  faPlus
} from '@fortawesome/free-solid-svg-icons';
import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

import ComponentStyles from '../../brand-styles/components/Header/MobileRecipes.styles';
import { breakpoints, colors, fonts } from '../../style-utilities/variables';
import StyledLink from '../StyledLink';
import placeHolderImage from '../../images/placeholder.png';

const Div = styled.div`
  /* position: relative; */
  height: 100vh;
  overflow-y: auto;
  background: ${colors.white};
  .title {
    color: ${colors.secondary};
    font-size: 20px;
  }
  .back {
    font-size: 20px;
    color: ${colors.secondary};
    /* outline: none; */
  }
  .search-box {
    margin: 18px;
  }
  .search-form {
    width: 100%;
    margin: 0 auto;
    border: 1px solid gray;
  }
  .accordion__title {
    /* outline: none; */
    strong {
      padding: 20px 15px;
    }
  }
  .accordion__plusMinus {
    top: 0;
    float: right;
    margin-top: 0;
    position: relative;
  }
  .subtopic,
  .hideBodyTitle {
    border: 1px solid ${colors.white};
    background: ${colors.secondary} !important;
    color: ${colors.white};
    padding: 15px 10px;
  }
  .topicTitle {
    font-size: 18px;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 0;

    font-family: ${fonts.secondaryMedium};
    color: ${colors.white};
    @media (min-width: ${breakpoints.tablet}) {
      font-size: 20px;
    }
  }
  ul {
    padding: 0;
  }
  .icon {
    position: relative;
    float: right;
  }
  .plus {
    display: none;
    transition-duration: 0.2s;
    transition-property: height;
  }
  .minus {
    display: inline;
    transition-duration: 0.2s;
    transition-property: height;
  }
  .hideBodyTitle {
    .plus {
      display: inline;
    }
    .minus {
      display: none;
    }
  }
  .view-buttons.top {
    margin-top: 20px;
  }
  .view-buttons {
    text-align: center;
    .view-all {
      color: ${colors.white};
      background: ${colors.secondary};
    }
  }
  /* Dynamic brand styles */
  ${ComponentStyles}
`;

class MobileRecipes extends Component {
  state = {
    activeAccordion: 1
  };

  activeAccordionHandler = clickedItem => {
    this.setState({ activeAccordion: clickedItem });
  };
  onChangeHandler = event => {
    const value = event.target.value;
    this.setState({ term: value });
  };
  submitHandler = event => {
    event.preventDefault();
    this.props.mainNavToggle();
    this.props.megaToggle();
    this.setState(() => {
      navigate(`/recipe-search?includes=${this.state.term}`);
    });
    event.target.reset();
  };
  checkDevice = () => {
    this.setState({
      handleAndroid: this.props.isAndroidDevice,
      activeAccordion: 0
    });
  };
  render() {
    const { megaToggle, mainNavToggle, siteType } = this.props;
    const androidClass = this.state.handleAndroid
      ? classNames({
          '-android-device': this.state.handleAndroid
        })
      : '';
    return (
      <StaticQuery
        query={graphql`
          query {
            allWordpressWpTaxonomies {
              edges {
                node {
                  name
                  slug
                  types
                }
              }
            }
            allWordpressWpProductLine {
              edges {
                node {
                  name
                  slug
                }
              }
            }
            allWordpressWpAttribute1 {
              edges {
                node {
                  slug
                  name
                  acf {
                    menu_image {
                      source_url
                    }
                  }
                }
              }
            }
            allWordpressWpMainIngredient {
              edges {
                node {
                  name
                  slug
                }
              }
            }
            allWordpressWpMealCourse {
              edges {
                node {
                  name
                  slug
                }
              }
            }
            allWordpressWpReadyIn {
              edges {
                node {
                  name
                  slug
                }
              }
            }
            allWordpressWpCookingMethod {
              edges {
                node {
                  name
                  slug
                }
              }
            }
            allWordpressWpDishType {
              edges {
                node {
                  name
                  slug
                }
              }
            }
            allWordpressWpSauce {
              edges {
                node {
                  name
                  slug
                }
              }
            }
          }
        `}
        render={data => {
          /**
           * Data from CMS
           */
          const productLine = data.allWordpressWpProductLine.edges;
          const attributeOne = data.allWordpressWpAttribute1.edges;
          const mainIngredient = data.allWordpressWpMainIngredient.edges;
          const mealCourse = data.allWordpressWpMealCourse.edges;
          const readyIn = data.allWordpressWpReadyIn.edges;
          const cookingMethod = data.allWordpressWpCookingMethod.edges;
          const dishType = data.allWordpressWpDishType.edges;
          const sauce = data.allWordpressWpSauce.edges;
          // const allTaxonomies = data.allWordpressWpTaxonomies.edges;
          // const taxonomies = allTaxonomies.filter(
          //   ({ node: { types } }) => types[0] === 'product'
          // );
          const columnTwo = [
            {
              name: 'Main Ingredient',
              image: placeHolderImage,
              slugs: mainIngredient.map(el => el.node.slug)
            },
            {
              name: 'Meal Part',
              image: placeHolderImage,
              slugs: mealCourse.map(el => el.node.slug)
            },
            {
              name: 'Cooking Method',
              image: placeHolderImage,
              slugs: cookingMethod.map(el => el.node.slug)
            },
            {
              name: 'Dish Type',
              image: placeHolderImage,
              slugs: dishType.map(el => el.node.slug)
            },
            {
              name: 'Total Time',
              image: placeHolderImage,
              slugs: readyIn.map(el => el.node.slug)
            },
            {
              name: 'By Sauce',
              image: placeHolderImage,
              slugs: sauce.map(el => el.node.slug)
            }
          ];
          /**
           * Render Categories Method
           */
          const renderRecipesByPastaType = () => {
            return (
              <ul>
                {attributeOne.map(({ node: { name, slug } }) => (
                  <Link
                    to={`/recipe-search?items=${slug}`}
                    onClick={() => {
                      mainNavToggle();
                      megaToggle();
                    }}
                    key={slug}
                  >
                    <li key={slug}>{ReactHtmlParser(name)}</li>
                  </Link>
                ))}
              </ul>
            );
          };
          /**
           * Render Column 2
           */
          const renderColumnTwo = () => {
            return (
              <ul>
                {columnTwo.map(item => (
                  <Link
                    key={item.name}
                    onClick={() => {
                      mainNavToggle();
                      megaToggle();
                    }}
                    to={`/recipe-search?items=${item.slugs}`}
                  >
                    <li>{item.name}</li>
                  </Link>
                ))}
                <Link
                  to="/recipe-categories"
                  className="view-all"
                  onClick={() => {
                    mainNavToggle();
                    megaToggle();
                  }}
                >
                  <li>View All Categories</li>
                </Link>
              </ul>
            );
          };
          /**
           * Array of Recipe Menu
           */
          const recipesMenu = [
            {
              id: 1,
              title: 'Recipes by Pasta Type',
              list: renderRecipesByPastaType()
            },
            {
              id: 2,
              title: 'Recipes by Category',
              list: renderColumnTwo()
            }
          ];
          return (
            <Div>
              <button className="back-button" onClick={megaToggle}>
                <FontAwesomeIcon icon={faArrowLeft} className="back" />
              </button>
              <div className="title">
                <strong>Recipes</strong>
              </div>
              {recipesMenu.map(item => {
                return (
                  <Accordion
                    key={item.id}
                    onClick={() => this.activeAccordionHandler(item.id)}
                  >
                    <AccordionItem
                      expanded={this.state.activeAccordion === item.id}
                    >
                      <AccordionItemTitle
                        className="subtopic"
                        hideBodyClassName="hideBodyTitle"
                      >
                        <h3 className="topicTitle">
                          {ReactHtmlParser(item.title)}
                        </h3>
                        <div className="accordion__plusMinus plus">
                          <FontAwesomeIcon id="icon" icon={faPlus} />
                        </div>
                        <div className="accordion__plusMinus minus">
                          <FontAwesomeIcon id="icon" icon={faMinus} />
                        </div>
                      </AccordionItemTitle>

                      <AccordionItemBody className="accordion-body">
                        {item.list}
                      </AccordionItemBody>
                    </AccordionItem>
                  </Accordion>
                );
              })}
              <div className="view-buttons top">
                <StyledLink
                  to="/recipe-collections"
                  className="view-all"
                  onClick={() => {
                    mainNavToggle();
                    megaToggle();
                  }}
                >
                  View All Collections
                </StyledLink>
              </div>
              <div className="view-buttons">
                <StyledLink
                  className="view-all"
                  link="/recipe-search"
                  onClick={() => {
                    mainNavToggle();
                    megaToggle();
                  }}
                >
                  View All Recipes
                </StyledLink>
              </div>
              <div className="search-box">
                <form onSubmit={this.submitHandler}>
                  <input
                    className="search-form"
                    placeholder="Search for recipes"
                    onChange={this.onChangeHandler}
                    onClick={this.checkDevice}
                  />
                </form>
              </div>
            </Div>
          );
        }}
      />
    );
  }
}

export default MobileRecipes;
