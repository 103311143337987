import React from 'react';
import styled from 'styled-components';
import ComponentStyles from '../../brand-styles/components/Header/CloseMenuButton.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const StyledButton = styled.button`
  color: white;
  position: absolute;
  right: 20px;
  top: 10px;
  border: none;
  background: transparent;
  font-size: 26px;
  padding: 0;

  /* Dynamic brand styles */
  ${ComponentStyles}
`;

const CloseMenuButton = ({ toggle }) => {
  return (
    <StyledButton onClick={toggle}>
      <FontAwesomeIcon icon={faTimes} />
    </StyledButton>
  );
};

export default CloseMenuButton;
