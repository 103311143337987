import React, { Component } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import ComponentStyles from '../../brand-styles/components/Header/MainNav.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleRight } from '@fortawesome/free-solid-svg-icons';

import { breakpoints, colors } from '../../style-utilities/variables';
import MegaMenu from './MegaMenu';

const StyledMainNav = styled.ul`
  grid-area: MainNav;
  /* //flex-grow: 2; */
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0 20px 30px;
  height: 65px;
  li {
    display: inline-block;
    margin: 0 20px;
    cursor: pointer;
    a {
      text-decoration: none;
      color: white;
      letter-spacing: 0.5px;
    }
  }
  .about-desktop {
    margin-right: 10px;
    :hover {
      opacity: 0.6;
    }
  }

  .mega-li {
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    :hover .header-dropdown-link,
    :hover svg {
      opacity: 0.6;
    }
    .header-dropdown-link {
      height: 65px;
      display: flex;
      align-items: center;
    }
  }

  li .arrowDown {
    display: inline-block;
    position: relative;
    margin: 0 2px;
    color: white;
  }
  li .arrowRight {
    display: inline-block;
    position: relative;
    top: 1px;
    margin: 0 5px;
    font-size: 28px;
  }

  @media (max-width: 899px) {
    margin: 0;
    padding: 20px;
    display: block;
    height: auto;
    background: #eaeaea;
    .home-mobile-nav {
      display: flex;
      justify-content: space-between;
      padding: 20px 20px 20px 0;
      font-size: 18px;
    }
    li,
    svg {
      margin: 0px;
      display: block;
    }

    .line {
      border: 0.5px solid ${colors.secondary};
    }
    .accordion-body {
      padding: 0;
      border: none;
      li {
        margin: 20px;
        border-bottom: 1px solid ${colors.secondary};
      }
    }
    h3 {
      display: inline;
    }
    .expand {
      position: relative;
      float: right;
      #expand_minus,
      #expand_plus {
      }
    }
    .back-button {
      background: transparent;
      border: none;
      position: absolute;
      top: 10px;
      left: 10px;
      .back {
        font-size: 20px;
        outline: none;
      }
    }

    .accordion__title {
      background: #eaeaea;
      outline: none;
    }
    .accordion {
      border: none;
    }
    .title {
      text-align: center;
      padding: 15px;
    }
    .view-buttons a {
      width: 280px;
      margin: 10px;
    }
  }
  @media (max-width: 500px) {
    padding: 0;
    .home-mobile-nav {
      padding: 15px;
    }
  }

  /* Dynamic brand styles */
  ${ComponentStyles}
`;

class MainNav extends Component {
  state = {
    viewPort: 'desktop',
    isBrowser: true,
    productsMegaOpen: false,
    recipesMegaOpen: false,
    mouseOverProductsButton: false,
    mouseOverProductsMenu: false,
    mouseOverRecipesButton: false,
    mouseOverRecipesMenu: false,
    isAndroidDevice: false
  };

  componentDidMount() {
    this.setState({
      isBrowser: typeof window !== 'undefined',
      viewPort: window.innerWidth >= 900 ? 'desktop' : 'mobile'
    });
    if (this.state.isBrowser) {
      window.addEventListener('resize', this.setViewport);
    }
    // Is Android.
    if (navigator !== undefined && navigator.userAgent !== undefined) {
      const user_agent = navigator.userAgent.toLowerCase();

      if (user_agent.indexOf('android') > -1) {
        this.setState({
          isAndroidDevice: true
        });
      }
    }
  }
  componentWillUnmount() {
    if (this.state.isBrowser) {
      window.removeEventListener('resize', this.setViewport);
    }
  }
  setViewport = () => {
    this.setState({
      viewPort:
        this.state.isBrowser && window.innerWidth >= 900 ? 'desktop' : 'mobile'
    });
  };
  //Function to check if Mouse is over the Button
  enterButton = trigger => {
    this.setState({ [`mouseOver${trigger}Button`]: true });
  };

  /**
   * Function to check if Mouse leaves the Button
   * SetTimeout is to give the Mouse time to move from Button to Mega Menu
   */
  leaveButton = trigger => {
    setTimeout(
      () => this.setState({ [`mouseOver${trigger}Button`]: false }),
      300
    );
  };
  //Function to check if Mouse is over the Mega Menu
  enterMenu = trigger => {
    this.setState({ [`mouseOver${trigger}Menu`]: true });
  };
  //Function to check if Mouse leaves the Mega Menu
  leaveMenu = trigger => {
    this.setState({ [`mouseOver${trigger}Menu`]: false });
  };
  //Function for toggling Mega Menu for Mobile version
  megaToggle = trigger => {
    this.setState({
      [`${trigger}MegaOpen`]: !this.state[`${trigger}MegaOpen`]
    });
  };
  //Function to close Mega Menu after an option being clicked
  clickToggle = trigger => {
    this.setState({
      [`mouseOver${trigger}Menu`]: false,
      [`mouseOver${trigger}Button`]: false
    });
    this.forceUpdate();
  };
  //Function to close Mega Menu after Products or Recipes in the Main Nav being clicked
  mainNavClickHandler = trigger => {
    this.setState({
      [`mouseOver${trigger}Menu`]: false,
      [`mouseOver${trigger}Button`]: false
    });
  };

  render() {
    //Check if Mouse is over the Button or Mega Menu for Products
    const openProducts =
      this.state.mouseOverProductsButton || this.state.mouseOverProductsMenu;
    //Check if Mouse is over the Button or Mega Menu for Recipes
    const openRecipes =
      this.state.mouseOverRecipesButton || this.state.mouseOverRecipesMenu;
    return (
      <StyledMainNav>
        {this.state.viewPort === 'desktop' ? (
          <li
            className="mega-li"
            onMouseEnter={() => this.enterButton('Products')}
            onMouseLeave={() => this.leaveButton('Products')}
          >
            <Link
              to="/products"
              onClick={() => this.mainNavClickHandler('Products')}
              className="header-dropdown-link"
            >
              Products&nbsp;
              <FontAwesomeIcon
                className="arrowDown"
                icon={faAngleDown}
                style={{ width: '10px', height: '16px' }}
              />
            </Link>

            <MegaMenu
              siteType={this.props.siteType}
              openMegaMenu={openProducts}
              enterMenu={this.enterMenu}
              leaveMenu={this.leaveMenu}
              clickToggle={() => this.clickToggle('Products')}
              isAndroidDevice={this.state.isAndroidDevice}
              name="Products"
              className="megaMenu-products"
            />
          </li>
        ) : (
          <li>
            <a
              onClick={() => this.megaToggle('products')}
              className="home-mobile-nav"
            >
              <div>Products</div>
              <div>
                <FontAwesomeIcon
                  className="arrowRight"
                  icon={faAngleRight}
                  style={{ width: '10px', height: '16px' }}
                />
              </div>
            </a>
            <div className="line" />
            <MegaMenu
              siteType={this.props.siteType}
              isMegaOpen={this.state.productsMegaOpen}
              megaToggle={() => this.megaToggle('products')}
              isAndroidDevice={this.state.isAndroidDevice}
              mainNavToggle={this.props.toggle}
              name="Products"
            />
          </li>
        )}

        {this.state.viewPort === 'desktop' ? (
          <li
            className="mega-li"
            onMouseEnter={() => this.enterButton('Recipes')}
            onMouseLeave={() => this.leaveButton('Recipes')}
          >
            <Link
              to={this.props.siteType === 'rice' ? '/recipes' : '/recipe-search'}
              onClick={() => this.mainNavClickHandler('Recipes')}
              className="header-dropdown-link"
            >
              Recipes&nbsp;
              <FontAwesomeIcon
                className="arrowDown"
                icon={faAngleDown}
                style={{ width: '10px', height: '16px' }}
              />
            </Link>

            <MegaMenu
              siteType={this.props.siteType}
              openMegaMenu={openRecipes}
              enterMenu={this.enterMenu}
              leaveMenu={this.leaveMenu}
              clickToggle={() => this.clickToggle('Recipes')}
              isAndroidDevice={this.state.isAndroidDevice}
              name="Recipes"
            />
          </li>
        ) : (
          <li>
            <a
              onClick={() => this.megaToggle('recipes')}
              className="home-mobile-nav"
            >
              <div>Recipes</div>
              <div>
                <FontAwesomeIcon
                  className="arrowRight"
                  icon={faAngleRight}
                  style={{ width: '10px', height: '16px' }}
                />
              </div>
            </a>
            <div className="line" />
            <MegaMenu
              siteType={this.props.siteType}
              isMegaOpen={this.state.recipesMegaOpen}
              megaToggle={() => this.megaToggle('recipes')}
              mainNavToggle={this.props.toggle}
              isAndroidDevice={this.state.isAndroidDevice}
              name="Recipes"
            />
          </li>
        )}
        {this.state.viewPort === 'desktop' ? (
          <li>
            <Link
              to="/about"
              onClick={this.props.toggle}
              className="about-desktop"
            >
              About
            </Link>
          </li>
        ) : (
          <li>
            {/* this.props.toggle is used for mobile to close the menu after About is clicked in the main nav  */}
            <Link
              to="/about"
              onClick={this.props.toggle}
              className="home-mobile-nav"
            >
              <div>About</div>
              <div>
                <FontAwesomeIcon
                  className="arrowRight"
                  style={{ width: '10px', height: '16px' }}
                />
              </div>
            </Link>
            <div className="line" />
          </li>
        )}
        {this.state.viewPort === 'mobile' ? (
          <li>
            <Link
              to="/where-to-buy"
              onClick={this.props.toggle}
              className="home-mobile-nav"
            >
              <div>Where to Buy</div>
            </Link>
          </li>
        ) : (
          ''
        )}
      </StyledMainNav>
    );
  }
}

export default MainNav;
