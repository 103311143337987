import React from 'react';
import styled from 'styled-components';
import ComponentStyles from '../../brand-styles/components/Footer/CopyRight.styles';
import { breakpoints } from '../../style-utilities/variables';

const StyledCopyRight = styled.div`
  grid-area: CopyRight;
  justify-self: center;
  padding: 15px 0 10px;

  @media (min-width: ${breakpoints.tablet}) {
    justify-self: right;
  }
  /* Dynamic Brand Styles */
  ${ComponentStyles}
`;

const CopyRight = () => {
  return <StyledCopyRight className="text-right copyright" data-siteid="135"></StyledCopyRight>;
};

export default CopyRight;


