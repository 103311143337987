import React from 'react';
import styled from 'styled-components';
import ComponentStyles from '../../brand-styles/components/Header/MenuToggle.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

import { breakpoints } from '../../style-utilities/variables';

const StyledMenuToggle = styled.button`
  background: transparent;
  color: white;
  border: none;
  svg {
    background: transparent;
    border: none;
    color: white;
  }
  @media (min-width: 900px) {
    display: none;
  }

  /* Dynamic brand styles */
  ${ComponentStyles}
`;

const MenuToggle = ({ toggleMobile }) => {
  return (
    <StyledMenuToggle onClick={toggleMobile}>
      <FontAwesomeIcon icon={faBars} pull="right" size="2x" />
    </StyledMenuToggle>
  );
};

export default MenuToggle;
