/**
 * generate a facebook friendly username
 * based on props of a user object
 * @param {Object} user
 */
export function generateUsername(type, user) {
  if (type === 'facebook') {
    return `${user.name.replace(' ', '').toLowerCase()}${user.id.slice(-4)}`;
  } else if (type === 'google') {
    return `${user.profileObj.name
      .replace(' ', '')
      .toLowerCase()}${user.googleId.slice(-4)}`;
  }
}

/**
 * takes a facebook user id and generates a
 * secure password using each digit and
 * mapping it to a corresponding alphanumeric
 * string in the keyString
 * @param {Integer} id
 */
export function generatePassword(id) {
  const keyString = process.env.PW_KEY_STRING;
  return id
    .split('')
    .map(int => keyString[int])
    .join('');
}
