import { css } from 'styled-components';

export default css`
  .wildcard {
    display: none !important;
  }
  li {
    padding-top: 0;
  }
`;
