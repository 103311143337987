import { css } from 'styled-components';
import { breakpoints } from '../../../style-utilities/variables';

export default css`
  img {
    display: block;
  }
  .TopFootContainer {
    max-width: none;
  }
`;
