import { css } from 'styled-components';
import { breakpoints, colors, fonts } from '../../../style-utilities/variables';

export default css`
  background-color: transparent;

  li a {
    color: ${colors.secondary};
    font-weight: bold;
    font-size: 18px;
    font-family: ${fonts.secondary};
  }
  @media (min-width: 900px) {
    li a {
      color: ${colors.white};
    }
    li {
      margin: 0 10px;
    }
  }
  @media (min-width: ${breakpoints.desktop}) {
    li {
      margin: 0 20px;
    }
  }
`;
