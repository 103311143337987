import React from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import styled from 'styled-components';
import ComponentStyles from '../../brand-styles/components/Forms/FacebookAuth.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';

const StyledFacebookAuth = styled.div`
  .facebook {
    background: #3b5998;
    color: white;
    display: block;
    padding: 15px 0px 15px 30px;
    width: 300px;
    margin: 10px auto;
    border: none;
    text-align: left;
    svg {
      width: 50px !important;
    }
  }
  /* Dynamic brand styles */
  ${ComponentStyles}
`;

const FacebookAuth = ({ authenticateUser }) => {
  return (
    <StyledFacebookAuth>
      <FacebookLogin
        appId={process.env.FACEBOOK_AUTH_APP_ID}
        autoLoad={false}
        fields="id,name,email,picture.width(100).height(100)"
        callback={authenticateUser}
        render={renderProps => (
          <button className="facebook" onClick={renderProps.onClick}>
            <FontAwesomeIcon icon={faFacebookF} /> Continue with Facebook
          </button>
        )}
      />
    </StyledFacebookAuth>
  );
};

export default FacebookAuth;
