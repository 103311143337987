import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { UiStateConsumer } from '../../context/UiStateContext';
import { UserStateConsumer } from '../../context/UserStateContext';
import { Form, Text } from 'informed';
import styled from 'styled-components';
import ComponentStyles from '../../brand-styles/components/Forms/SignUpSignInFormMobile.styles';
import FacebookAuth from './FacebookAuth';
import GoogleAuth from './GoogleAuth';
import RegularButton from '../RegularButton';
import WhiteButton from '../WhiteButton';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faTimes } from '@fortawesome/free-solid-svg-icons';

const StyledSignupFormSimpleMobile = styled.div`
  display: flex;
  overflow-y: scroll; /* has to be scroll, not auto */
  -webkit-overflow-scrolling: touch;
  padding-bottom: 400px;

  .account-warning {
    font-size: 0.8rem;
    padding: 0.8rem;
    background-color: #79090e;
  }
  .img-container {
    flex: 1 55%;

    img {
      height: 100%;
      max-width: 100%;
      object-fit: cover;
    }
  }

  .content {
    position: relative;
    padding: 5%;
    flex: 1 45%;
    text-align: center;
    @media (max-width: 400px) {
      overflow: scroll; /* has to be scroll, not auto */
      -webkit-overflow-scrolling: touch;
      padding-bottom: 5em;
    }
    .header-sign-in {
      margin-top: 0;
    }
    .why-sign-up {
      p {
        margin: 8px;
      }
    }
    .sign-up-email {
      display: flex;
      align-items: center;
      justify-content: center;
      .line {
        display: inline-block;
        width: 80px;
        height: 1px;
        background: white;
      }
    }
  }

  #username,
  #email,
  #password {
    width: 300px;
    margin: 0 auto;
    border: 1px solid #efefef;
  }

  .input-group {
    margin-top: 20px auto;
  }
  .signin-form {
    margin-top: 12px;
  }
  .white-button {
    width: 300px;
    margin-top: 20px;
    padding: 14px 0 10px;
  }
  .forgot {
    margin-top: 20px;
    text-decoration: underline;
    border: none;
    background-color: transparent;
    color: #333333;
  }
  .button-link {
    text-decoration: underline;
    border: none;
    background: transparent;
    color: #333333;
  }
  .close-button {
    background-color: transparent;
    position: relative;
    left: 140px;
    border: none;
    color: #000000;
  }
  /* Dynamic brand styles */
  ${ComponentStyles}
`;

const ErrorMessage = styled.span`
  color: white;
  display: none;

  &.show {
    display: block;
  }
`;

class SignUpSignInFormMobile extends Component {
  loginFields = [
    {
      name: 'email',
      type: 'text',
      placeholder: 'Email'
    },
    {
      name: 'password',
      type: 'password',
      placeholder: 'Password'
    }
  ];

  fields = [
    // { name: 'username', type: 'text', placeholder: 'Username' },
    { name: 'email', type: 'text', placeholder: 'Email' },
    { name: 'password', type: 'password', placeholder: 'Password' }
  ];

  renderSignInInputs = () =>
    this.loginFields.map(field => (
      <div key={field.name} className="input-group">
        <Text
          placeholder={field.placeholder}
          field={field.name}
          id={field.name}
          type={field.type}
        />
      </div>
    ));

  renderInputs = () =>
    this.fields.map(field => (
      <div key={field.name} className="input-group">
        <Text
          placeholder={field.placeholder}
          field={field.name}
          id={field.name}
          type={field.type}
        />
      </div>
    ));

  render() {
    return (
      <UiStateConsumer>
        {uiStateContext => {
          return (
            <StyledSignupFormSimpleMobile>
              <UserStateConsumer>
                {userContext => (
                  <div className="content">
                    {this.props.showHeading && (
                      <h2>{ReactHtmlParser(this.props.heading)}</h2>
                    )}
                    <button
                      className="close-button"
                      onClick={() => {
                        uiStateContext.closeSignInForm();
                        uiStateContext.closeSignUpForm();
                      }}
                    >
                      <FontAwesomeIcon
                        className="close"
                        icon={faTimes}
                        style={{ width: '20px', height: '20px' }}
                      />
                    </button>
                    <h2 className="header-sign-in">
                      {uiStateContext.showSignInForm ? 'Sign In' : 'Sign Up'}
                    </h2>
                    <div className="input-group">
                      <FacebookAuth
                        authenticateUser={user =>
                          userContext.authenticateUser('facebook', user)
                        }
                      />
                    </div>
                    <div className="input-group">
                      <GoogleAuth
                        authenticateUser={user =>
                          userContext.authenticateUser('google', user)
                        }
                      />
                    </div>
                    <h2 className="sign-up-email">
                      <div className="line" />
                      &nbsp; Or &nbsp;
                      <div className="line" />
                    </h2>

                    <Form
                      className="signup-form"
                      style={{
                        display: uiStateContext.showSignUpForm
                          ? 'block'
                          : 'none'
                      }}
                      onSubmit={user => {
                        userContext.createUser('form', user);
                      }}
                    >
                      {this.renderInputs()}
                      <WhiteButton type="submit">
                        {userContext.requestPending
                          ? 'Creating...'
                          : 'Create Your Acccount'}
                      </WhiteButton>
                    </Form>

                    <Form
                      className="signin-form"
                      style={{
                        display: uiStateContext.showSignInForm
                          ? 'block'
                          : 'none'
                      }}
                      onSubmit={user => {
                        userContext.authenticateUser('form', user);
                      }}
                    >
                      {this.renderSignInInputs()}
                      <WhiteButton type="submit">
                        {userContext.requestPending
                          ? 'Signing you in...'
                          : 'Log In'}
                      </WhiteButton>
                    </Form>
                    <button
                      style={{
                        display: uiStateContext.showSignInForm
                          ? 'inline'
                          : 'none'
                      }}
                      type="button"
                      className="forgot"
                      onClick={() => {
                        setTimeout(() => {
                          uiStateContext.toggleModal('forgotpassword');
                        }, 0);
                      }}
                    >
                      {' '}
                      Forgot Password
                    </button>
                    <p
                      style={{
                        display: uiStateContext.showSignInForm
                          ? 'none'
                          : 'block'
                      }}
                    >
                      Already have an account?{' '}
                      <button
                        className="button-link"
                        type="button"
                        onClick={uiStateContext.switchForms}
                      >
                        Log In
                      </button>
                    </p>

                    <p
                      style={{
                        display: uiStateContext.showSignInForm
                          ? 'block'
                          : 'none'
                      }}
                    >
                      Don't have an account?{' '}
                      <button
                        className="button-link"
                        type="button"
                        onClick={uiStateContext.switchForms}
                      >
                        Sign Up
                      </button>
                    </p>

                    <div className="errors">
                      {userContext.errors.map(error => {
                        if (
                          error.message ===
                          'Username already exists, please enter another username'
                        ) {
                          error.message =
                            'Email already exists. Did you sign up through Facebook or Google?';
                        }
                        return (
                          <div
                            key={`${error.field}-${error.message}`}
                            className="error-message"
                            style={{ color: 'red' }}
                          >
                            {error.message}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </UserStateConsumer>
            </StyledSignupFormSimpleMobile>
          );
        }}
      </UiStateConsumer>
    );
  }
}
export default SignUpSignInFormMobile;
