import React, { Component } from 'react';
import { UserStateConsumer } from './UserStateContext';
import ReviewsService from '../services/reviews.service';
import UserService from '../services/user.service';

export const ReviewsStateContext = React.createContext();
const ReviewsStateProvider = ReviewsStateContext.Provider;
export const ReviewsStateConsumer = ReviewsStateContext.Consumer;

class ReviewsState extends Component {
  constructor(props) {
    super(props);

    this.reviewsService = new ReviewsService();
    this.userService = new UserService();

    this.state = {
      reviews: [],
      reviewsCount: {},
      existingReview: this.getInitialReviewState(),
      existingReviewErrors: {},

      setExistingReviewState: state => {
        this.setState({
          existingReview: { ...this.state.existingReview, ...state }
        });
      },

      setExistingReviewErrors: errors => {
        this.setState({
          existingReviewErrors: errors
        });
      },

      resetExistingReviewState: () => {
        this.setState({
          existingReview: this.getInitialReviewState(),
          existingReviewErrors: {}
        });
      },

      getExistingReviewState: () => {
        return this.state.existingReview;
      },

      getExistingReview: (type, slug) => {
        const user = this.userService.getAuthenticatedUser();
        this.state.resetExistingReviewState();

        if (user) {
          this.reviewsService
            .getReview(user, type, slug)
            .then(existingReview => {
              if (existingReview && existingReview.id) {
                this.state.setExistingReviewState(existingReview);
              }
            });
        }
      },

      //adds and also updates
      add: (type, slug, item) => {
        const user = this.userService.getAuthenticatedUser();

        if (user) {
          return this.reviewsService
            .createReview(user, type, slug, item)
            .then(() => {
              this.state.getReviewsForItem(type, slug);
              this.state.getReviewsCountForItem(type, slug);
              this.state.getExistingReview(type, slug);
            });
        } else {
          return Promise.resolve();
        }
      },
      getReviewsForItem: (type, slug) => {
        this.reviewsService.getReviewsForItem(type, slug).then(data => {
          this.setState({ reviews: data });
        });
      },
      getReviewsCountForItem: (type, slug) => {
        this.reviewsService.getReviewsCountForItem(type, slug).then(data => {
          this.setState({ reviewsCount: data });
        });
      }
      //   deleteItem: item => {
      //     const user = this.userService.getAuthenticatedUser();

      //     if (user && item && item.name) {
      //       this.shoppingListService
      //         .deleteShoppingListItem(user, item.name)
      //         .then(result => {
      //           this.state.getItems();
      //         });
      //     }
      //   }
    };
  }

  getInitialReviewState() {
    const user = this.userService.getAuthenticatedUser();

    return {
      stars: 0,
      title: '',
      text: '',
      hideProfileImage: false,
      userAvatar:
        user && user.avatar_urls && user.avatar_urls[96]
          ? user.avatar_urls[96]
          : ''
    };
  }

  render() {
    return (
      <ReviewsStateProvider value={this.state}>
        {this.props.children}
      </ReviewsStateProvider>
    );
  }
}

export default React.forwardRef((props, ref) => (
  <UserStateConsumer>
    {userContext => (
      <ReviewsState {...props} userContext={userContext} ref={ref} />
    )}
  </UserStateConsumer>
));
