import { css } from 'styled-components';
import { breakpoints, colors } from '../../../style-utilities/variables';

export default css`
  padding-top: 5px;
  padding-bottom: 0px;
  border-bottom: 2px solid #f1f1f1;
  @media (min-width: ${breakpoints.generalDesktop}) {
    padding-top: 6px;
    border-bottom: 0px;
  }
  .where-to-buy-link {
    font-weight: 700;
    font-size: 22px;
    padding-top: 16px;
    
    @media (min-width: ${breakpoints.generalDesktop}) {
      font-size: 18px;
      padding-top: 13px;
    }
    svg {
      /* margin-bottom: 3px; */
    }
    @media (min-width: 900px) {
      padding-left: 20px;
      padding-right: 20px;
    }
    @media (min-width: ${breakpoints.desktop}) {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
`;
