import React, { Component } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

import RegularButton from '../RegularButton';
import { breakpoints, colors } from '../../style-utilities/variables';
import { ShoppingListStateConsumer } from '../../context/ShoppingListContext';

const NoteSection = styled.div`
  margin: 0 auto;
  width: 90%;
  h4 {
    color: ${colors.primary};
    font-size: 22px;
    margin-bottom: 8px;
  }
  textarea {
    height: 200px;
    width: 100%;
    border: 1px solid lightgray;
  }
`;

const AddItemModal = styled.div`
  display: none;
  height: auto;
  padding: 20px;
  background: white;
  margin: 10px auto;
  &.modal-open {
    display: block;
  }
  label {
    display: flex;
    margin: 10px 20px;
  }
  input,
  select {
    width: 90%;
    margin: 5px auto;
  }
  button {
    display: inline-block;
    margin: 20px 10px;
  }
`;

const Div = styled.div`
  /* overflow: scroll; */
  background: white;
  z-index: 200;
  padding: 20px 10px;
  /* width: 35vw; */
  right: -100%;
  visibility: hidden;
  min-height: 70vh;
  top: 0;
  position: fixed;
  transition: 0.3s right ease-out, 0.3s visibility ease-out;

  .drawer-content-wrapper {
    height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
  }

  &.shop-open {
    opacity: 1;
    right: 0;
    visibility: visible;
    z-index: 600;
    @media (min-width: 768px) {
      width: 420px;
    }
  }
  #close-button {
    display: none;
    border: none;
    background: transparent;
  }
  #shopping-button-right {
    outline: none;
    color: ${colors.primary};
    transform: rotate(-90deg);
    left: -141px;
    top: 50%;
    position: absolute;
    cursor: pointer;
    border: none;
    padding: 12px 14px 12px;
    border-top: 2px solid ${colors.primary};
    border-right: 2px solid ${colors.primary};
    border-left: 2px solid ${colors.primary};
    border-bottom: transparent;
    #caret {
      width: 15px;
      fill: ${colors.primary};
    }
  }
  .top-bar {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 10px;
    .person,
    .favorite {
      border: 1px solid lightgray;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      a {
        color: ${colors.primary};
        text-decoration: none;
      }
      svg {
        color: ${colors.primary};
        height: 24px;
        width: 24px;
      }
    }
    button {
      margin-top: 18px;
      color: ${colors.primary};
      padding: 10px;
      border: 1px solid lightgray;
    }
    .favorite {
      display: flex;
      align-items: center;
      button {
        border: none;
        background: transparent;
      }
    }
  }
  .main-list {
    text-align: center;
    h2 {
      color: ${colors.primary};
    }
    .add-shopping-list {
      width: 90%;
      margin: 0 auto;
      box-shadow: none;
      border: 1px solid lightgray;
      color: ${colors.primary};
      text-align: left;
      padding: 10px;
      margin-bottom: 20px;
    }
  }
  @media (max-width: ${breakpoints.tablet}) {
    -webkit-overflow-scrolling: touch;
    #shopping-button-right {
      display: none;
    }
    #close-button {
      display: block;
      font-weight: bold;
      padding: 0 5px;
      margin: 0 0 5px 0;
    }
    .top-bar {
      .person,
      .favorite {
        padding: 0;
      }
    }
    ${NoteSection} {
      textarea {
        height: 100px;
      }
      .button {
        margin: 5px;
        padding: 5px;
        display: inline;
      }
    }
    ${AddItemModal} {
      padding: 5px;
      input,
      select {
        margin: 5px;
      }
      input,
      select {
        border: 1px solid black;
      }
      input {
        padding: 5px;
      }
      .modal-button {
        margin: 5px;
        padding: 5px 10px;
      }
    }
  }
`;

class ShoppingListEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ingName: props.item.name,
      qty: props.item.quantity || 1,
      unit: props.item.unit || 'units'
    };
  }

  closeModal = event => {
    event.preventDefault();
    this.props.close();
  };

  onItemChangeHandler = event => {
    const value = event.target.value;
    this.setState({ [event.target.name]: value });
  };
  onQtyChangeHandler = event => {
    const value = parseInt(event.target.value, 10);
    var valInt = /^\d+$/.test(value);
    valInt && valInt >= 0
      ? this.setState({ [event.target.name]: value })
      : this.setState({ [event.target.name]: '' });
  };

  addToShoppingList = (event, addFn) => {
    event.preventDefault();

    const name = this.state.ingName;

    if (name) {
      const qty = this.state.qty || 1;
      const unit = this.state.unit;

      addFn([
        {
          name: name,
          quantity: qty,
          unit: unit
        }
      ]).then(() => {
        this.props.close();
      });
    }
  };

  render() {
    const openModalClass = classNames({
      'modal-open': true
    });

    return (
      <div>
        <ShoppingListStateConsumer>
          {shoppingListContext => {
            return (
              <AddItemModal className={openModalClass}>
                <form>
                  <h2>Edit Ingredient</h2>
                  <em>{this.state.ingName}</em>
                  <br />

                  <input
                    type="text"
                    name="qty"
                    placeholder="Quantity"
                    onChange={this.onQtyChangeHandler}
                    value={this.state.qty}
                  />
                  <select
                    name="unit"
                    value={this.state.unit}
                    onChange={this.onItemChangeHandler}
                  >
                    <option value="units">Units</option>
                    <option value="pounds">Pounds</option>
                    <option value="ounces">Ounces</option>
                  </select>
                  <RegularButton
                    className="modal-button"
                    onClick={this.closeModal}
                  >
                    Cancel
                  </RegularButton>
                  <RegularButton
                    className="modal-button"
                    onClick={e => {
                      this.addToShoppingList(e, shoppingListContext.addItems);
                    }}
                  >
                    Update
                  </RegularButton>
                </form>
              </AddItemModal>
            );
          }}
        </ShoppingListStateConsumer>
      </div>
    );
  }
}

export default ShoppingListEdit;
