import React, { Component } from 'react';
import { generatePassword, generateUsername } from '../utilities/userUtils';
import { navigate } from 'gatsby';
import md5 from 'crypto-js/md5';
import FavoritesService from '../services/favorites.service';
// context
const UserStateContext = React.createContext();
const UserStateProvider = UserStateContext.Provider;
export const UserStateConsumer = UserStateContext.Consumer;

class UserState extends Component {
  WP_BASE_URI = process.env.WP_BASE_URI;
  REGISTER_URL = `${this.WP_BASE_URI}${process.env.WP_REGISTER_ENDPOINT}`;
  AUTH_URL = `${this.WP_BASE_URI}${process.env.WP_AUTH_ENDPOINT}`;
  PROFILE_URL = `${this.WP_BASE_URI}${process.env.WP_USER_PROFILE_ENDPOINT}`;
  USER_ACF_URL = `${this.WP_BASE_URI}${process.env.WP_USER_ACF_ENDPOINT}`;
  USER_RESET_PASSWORD_URL = `${this.WP_BASE_URI}${
    process.env.WP_USER_PROFILE_RESETPASSWORD_ENDPOINT
  }`;

  constructor(props) {
    super(props);

    this.favoriteService = new FavoritesService();

    this.state = {
      passwordResetStatus: false,
      wpUserName: null,
      userState: null,
      // we keep track of total number of favorites
      favoritesCount: 0,
      // handles 'window not defined' errors on build
      isBrowser: typeof window !== 'undefined',
      // handle loading indicator
      requestPending: false,
      // check for an authenticated user
      authenticatedUser:
        typeof localStorage !== 'undefined'
          ? JSON.parse(localStorage.getItem('authenticatedUser')) || null
          : null,
      updateFavoritesCount: count => {
        this.setState({
          favoritesCount: count
        });
      },
      updateUserState: type => {
        this.setState({
          userState: type
        });
      },
      updateAuthenicatedUser: user => {
        this.setState({
          authenticatedUser: user
        });
      },
      /**
       * get total favorites count
       */
      getTotalFavoritesCount: () => {
        const type = 'recipes';
        this.favoriteService
          .getFavoritesForUser(this.state.authenticatedUser, type)
          .then(result => {
            if (result) {
              this.state.updateFavoritesCount(result.length);
            }
          });
      },
      /**
       * create a wordpress user
       */
      createUser: async (type, user) => {
        // clear error fields
        this.setState({
          errors: []
        });
        // initial value for success
        let userCreated = false;
        const { username, email, password } = this.setCredentials(type, user);
        // start loader
        this.setState({ requestPending: true });
        // create the user
        const response = await fetch(this.REGISTER_URL, {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            username,
            email,
            password
          })
        });
        // assign user data to the userJson variable
        const responseJson = await response.json();
        responseJson['type'] = type;
        userCreated = responseJson.code === 200;
        // success
        if (userCreated) {
          alert('This user has been created! Logging you in...');
          this.state.authenticateUser(type, user, true);
        } else if (responseJson.code !== 200) {
          this.setState({
            requestPending: false,
            errors: [
              ...this.state.errors,
              { field: 'email', message: responseJson.message }
            ]
          });
        }
      },
      /**
       * handle all login types and authenticate to the WP api
       */
      authenticateUser: async (type, user, isNew) => {
        // reset errors
        this.setState({
          errors: []
        });
        // declare a variable to assign user data to
        let userJson = null;
        let loginAttempt;
        const { username, password } = this.setCredentials(type, user);
        // start loader
        this.setState({ requestPending: true });
        // attempt to log in with the credentials
        loginAttempt = await this.WPuserLogin({ username, password });
        // if there's no user, create one

        if (loginAttempt.success) {
          userJson = loginAttempt.user;
        } else {
          type !== 'form'
            ? this.state.createUser(type, user)
            : this.setState({
                errors: [...this.state.errors, loginAttempt.error]
              });
        }
        // set the authenticated user
        if (userJson) {
          let userProfile;
          const token = userJson.token;
          // Save user token to storage so it can be resued with other requests.
          localStorage.setItem('user-wp-token', token);
          // Delete the password and confirmPassword attributes so they don't get stored in localStorage. Could there be a better way?
          delete user.password;
          delete user.confirmPassword;
          // end

          userProfile = await this.getUserProfile(token);
          // Here i reassign the wordpress user id and not the facebook id since this is what is stored in localstoraged and used on the sit
          user.id = userProfile.id;
          const mergedUserProfile = { ...userProfile, ...user };
          mergedUserProfile['auth_type'] = type;
          if (isNew) {
            // update the user with the needed fields
            const userWithFields = await this.updateUserFields(
              mergedUserProfile
            );
            navigate('/user/profile');
          }
          this.state.setAuthenticatedUser(JSON.stringify(mergedUserProfile));
          this.setState({ requestPending: false });
          if (this.state.userState === 'resetPassword') {
            this.setState({ userState: null });
            navigate('/user/profile');
          }
          this.state.getTotalFavoritesCount();
        }
      },
      /**
       * update a user's attributes
       */
      updateUser: async userFields => {
        // Here we save the current user state from localStorge to preserve the social provider profile images
        const currentUserState = JSON.parse(
          localStorage.getItem('authenticatedUser')
        );
        const updatedUser = await this.updateUserFields(userFields);
        const userProfile = await this.getUserProfile(
          localStorage.getItem('user-wp-token')
        );

        // Here we reassign those social profiles with their profile photos
        if (currentUserState.auth_type === 'google') {
          userProfile['profileObj'] = currentUserState.profileObj;
        } else if (currentUserState.auth_type === 'facebook') {
          userProfile['picture'] = currentUserState.picture;
        }
        userProfile['auth_type'] = currentUserState.auth_type;
        localStorage.setItem('authenticatedUser', JSON.stringify({}));
        localStorage.setItem('authenticatedUser', JSON.stringify(userProfile));
        this.state.setAuthenticatedUser(JSON.stringify(userProfile));
        localStorage.getItem('user-wp-token');
        return updatedUser;
      },
      /**
       * save the user to localstorage for use within the application
       */
      setAuthenticatedUser: user => {
        if (this.state.isBrowser) {
          localStorage.setItem('authenticatedUser', user);
          this.setState({
            authenticatedUser: JSON.parse(
              localStorage.getItem('authenticatedUser')
            )
          });
        }
      },
      // clear local storage and redirect the user home
      logout: () => {
        if (!this.state.isBrowser) return false;

        if (window.confirm('Are you sure you want to log out?')) {
          localStorage.removeItem('authenticatedUser');
          localStorage.removeItem('user-wp-token');
          this.setState(
            {
              authenticatedUser: null,
              favoritesCount: null
            },
            () => navigate('/')
          );
        }
      },
      logoutWithoutConfirm: () => {
        if (!this.state.isBrowser) return false;
        localStorage.removeItem('authenticatedUser');
        localStorage.removeItem('user-wp-token');
        this.setState({
          authenticatedUser: null,
          favoritesCount: null
        });
      },
      updatePassword: async user => {
        delete user.confirmpassword;
        const response = await fetch(this.USER_RESET_PASSWORD_URL, {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ email: user.email, password: user.password })
        });
        const responseJson = await response.json();
        this.setState({
          passwordResetStatus: responseJson.status,
          wpUserName: responseJson.username
        });
        if (responseJson.status) {
          setTimeout(() => {
            this.setState({
              passwordResetStatus: false
            });
          }, 4000);
        }
        return responseJson;
      },
      errors: []
    };
  }
  // uses the provided user object to create a wordpress-ready
  // user object with the properly formatted username and password
  setCredentials = (type, user) => {
    let username;
    let email;
    let password;

    // check auth type and handle accordingly
    switch (type) {
      // if this is a social login attempt, use the user info to get WP credentials
      case 'facebook':
        username = md5(user.email).toString();
        email = user.email;
        // To fix the issue with facebook login I had to use username to generate the password. So it's consistent. The facebook ID coming back was differnet for each attempt to login/or sign up per site.
        password = generatePassword(username.toString());
        break;
      case 'google':
        username = md5(user.profileObj.email).toString();
        email = user.profileObj.email;
        password = generatePassword(user.googleId.toString());
        break;
      // authenticate the user to our wordpress backend
      case 'form':
        username = md5(user.email).toString();
        email = user.email;
        password = user.password;
        break;
      default:
        return;
    }

    return { username, email, password, type };
  };

  /**
   * attempt to log in to wordpress with the provided user
   * return a detailed response
   */
  WPuserLogin = async user => {
    const response = await fetch(this.AUTH_URL, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(user)
    });
    const login = await response.json();

    if (
      login.hasOwnProperty('code') &&
      login.code.includes('incorrect_password')
    ) {
      this.setState({ requestPending: false });
      return {
        success: false,
        error: { field: 'password', message: 'Incorrect password...' }
      };
    } else if (
      login.hasOwnProperty('code') &&
      login.code.includes('invalid_username')
    ) {
      this.setState({ requestPending: false });
      return {
        success: false,
        error: { field: 'username', message: 'Incorrect username...' }
      };
    } else if (
      login.hasOwnProperty('code') &&
      login.code.includes('empty_username')
    ) {
      this.setState({ requestPending: false });
      return {
        success: false,
        error: {
          field: 'username',
          message: 'The username field is required...'
        }
      };
    } else if (
      login.hasOwnProperty('code') &&
      login.code.includes('empty_password')
    ) {
      this.setState({ requestPending: false });
      return {
        success: false,
        error: {
          field: 'username',
          message: 'The password field is required...'
        }
      };
    } else if (
      login.hasOwnProperty('code') &&
      login.code.includes('empty_email')
    ) {
      this.setState({ requestPending: false });
      return {
        success: false,
        error: {
          field: 'username',
          message: 'The email field is required...'
        }
      };
    } else if (login.hasOwnProperty('code')) {
      this.setState({ requestPending: false });
      return {
        success: false,
        error: { field: null, message: 'There was an issue...' }
      };
    } else {
      return { success: true, user: login };
    }
  };

  /**
   * fetch a user's profile using the jwt
   */
  getUserProfile = async token => {
    const response = await fetch(this.PROFILE_URL, {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    return response.json();
  };
  /**
   * update a user's base profile fields
   */
  updateUserFields = async userFields => {
    const UPDATE_URL = `${process.env.WP_BASE_URI}${
      process.env.WP_UPDATE_USER_ENDPOINT
    }/${userFields.id}`;
    const { first_name, last_name, meta } = userFields;

    const response = await fetch(UPDATE_URL, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('user-wp-token')}`
      },
      body: JSON.stringify({ first_name, last_name, meta: { ...meta } })
    });
    return response.json();
  };
  /**
   * Update ACF fields associated with a user
   */
  // updateUserAcf = async fields => {
  //   const user = this.state.authenticatedUser;
  //   const UPDATE_ACF_URL = `${this.USER_ACF_URL}/${user.id}`;
  //   const response = await fetch(UPDATE_ACF_URL, {
  //     method: 'PUT',
  //     mode: 'cors',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       Authorization: `Bearer ${localStorage.getItem('user-wp-token')}`
  //     },
  //     body: JSON.stringify({
  //       fields: { ...fields }
  //     })
  //   });
  // };

  componentDidUpdate() {
    if (this.state.authenticatedUser) {
      // Here we just test that the user is still autenitcated.
      fetch(`${this.WP_BASE_URI}/wp-json/jwt-auth/v1/token/validate`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('user-wp-token')}`
        },
        body: JSON.stringify({})
      })
        .then(json => {
          if (json.status === 401 || json.status === 403) {
            this.state.logoutWithoutConfirm();
          }
        })
        .catch(error => {
          console.error(error);
        });
    }
  }

  render() {
    return (
      <UserStateProvider value={this.state}>
        {this.props.children}
      </UserStateProvider>
    );
  }
}

export default UserState;
