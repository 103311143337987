import React, { Component } from 'react';
import { navigate } from '@reach/router';
import { graphql, StaticQuery, Link } from 'gatsby';
import styled from 'styled-components';
import ComponentStyles from '../../brand-styles/components/Header/DesktopProducts.styles';
import ReactHtmlParser from 'react-html-parser';

import { fonts, colors } from '../../style-utilities/variables';
import StyledLink from '../StyledLink';

const OpacityContainer = styled.div`
  .content {
    padding: 40px;
    display: -ms-grid;
    display: grid;
    opacity: 1;
    background: white;
    grid-template-columns: repeat(4, 1fr);
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-areas: 'col1 col2 col3 col4';
    .col-1,
    .col-2,
    .col-3,
    .col-4 {
      display: inline-block;
      text-align: left;
      height: inherit;
      width: 20vw;
      li {
        display: block;
      }
      ul {
        padding-top: 10px;
      }
      p {
        color: ${colors.bodyText};
        font-family: ${fonts.secondary};
      }
    }
    .col-1 {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      grid-area: col1;
      -ms-grid-column: 1;
      grid-column: 1;
      img {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 15vw;
      }
    }
    .col-2 {
      grid-area: col2;
      -ms-grid-column: 2;
      grid-column: 2;
      li:hover {
        text-decoration: underline;
      }
    }
    .col-3 {
      grid-area: col3;
      -ms-grid-column: 3;
      grid-column: 3;
      li:hover {
        text-decoration: underline;
      }
    }
    .col-4 {
      grid-area: col4;
      -ms-grid-column: 4;
      grid-column: 4;
      .styled-link {
        width: 100%;
        color: ${colors.white};
        font-size: 20px;
        padding: 10px 40px 3px;
      }
    }
  }

  /* Dynamic brand styles */
  ${ComponentStyles}
`;

class DesktopProducts extends Component {
  state = {
    hoverImage: ''
  };

  renderHoverImage = source => {
    this.setState({ hoverImage: source });
  };
  mouseLeaveItem = source => {
    this.setState({ hoverImage: source });
  };

  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            allWordpressWpTaxonomies {
              edges {
                node {
                  name
                  slug
                  types
                }
              }
            }
            allWordpressWpProductLine {
              edges {
                node {
                  name
                  slug
                  acf {
                    menu_image {
                      source_url
                    }
                  }
                }
              }
            }
            allWordpressWpAttribute1 {
              edges {
                node {
                  slug
                  name
                  acf {
                    menu_image {
                      source_url
                    }
                  }
                }
              }
            }
            allWordpressWpAttribute2 {
              edges {
                node {
                  slug
                  name
                }
              }
            }
          }
        `}
        render={data => {
          //Data from CMS
          const productLine = data.allWordpressWpProductLine.edges;
          const attributeOne = data.allWordpressWpAttribute1.edges;
          const allTaxonomies = data.allWordpressWpTaxonomies.edges;
          const productTaxonomies = allTaxonomies.filter(
            ({ node: { types } }) => types[0] === 'product'
          );
          const initialImage = productLine[0].node.acf.menu_image.source_url;
          // const clickHandler = (event, value) => {
          //   event.preventDefault();
          //   navigate(`/products?option=${value}`);
          // };
          /**
           * Render Product Categories Method
           */
          const renderProductLine = () => {
            return (
              <ul>
                {productLine.map(({ node: { name, slug, acf } }) => (
                  <Link
                    to={`/product-categories/${slug}`}
                    onClick={this.props.megaToggle}
                    key={slug}
                  >
                    <li
                      key={slug}
                      onMouseEnter={() =>
                        this.renderHoverImage(acf.menu_image.source_url)
                      }
                      onMouseLeave={() => this.mouseLeaveItem(initialImage)}
                    >
                      {ReactHtmlParser(name)}
                    </li>
                  </Link>
                ))}
              </ul>
            );
          };
          /**
           * Render Attribute 1 Method
           */
          const renderAttributeOne = () => {
            return (
              <ul>
                {attributeOne.map(({ node: { name, slug, acf } }) => (
                  <Link
                    to={`/products?option=${slug}`}
                    onClick={this.props.megaToggle}
                    key={slug}
                  >
                    <li
                      key={slug}
                      onMouseEnter={() =>
                        this.renderHoverImage(acf.menu_image.source_url)
                      }
                      onMouseLeave={() => this.mouseLeaveItem(initialImage)}
                    >
                      {ReactHtmlParser(name)}
                    </li>
                  </Link>
                ))}
              </ul>
            );
          };
          return (
            <OpacityContainer>
              <div className="content">
                <span className="col-1">
                  {this.state.hoverImage ? (
                    <img src={this.state.hoverImage} alt="menuImg" />
                  ) : (
                    <img src={initialImage} alt="menuImg" />
                  )}
                </span>
                <div className="col-2">
                  <p>Pasta Line</p>
                  {renderProductLine()}
                </div>
                <div className="col-3">
                  <p>Pasta Type</p>
                  {renderAttributeOne()}
                </div>
                <div className="col-4">
                  <p>Not Sure?</p>
                  <StyledLink
                    className="styled-link "
                    link="/products"
                    onClick={this.props.megaToggle}
                  >
                    View All Products
                  </StyledLink>
                </div>
              </div>
            </OpacityContainer>
          );
        }}
      />
    );
  }
}

export default DesktopProducts;
