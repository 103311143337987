import React, { Component } from 'react';
import { UserStateConsumer } from './UserStateContext';
// context
export const UiStateContext = React.createContext();
const UiStateProvider = UiStateContext.Provider;
export const UiStateConsumer = UiStateContext.Consumer;

class UiState extends Component {
  state = {
    // we keep track of total number of favorites
    favoritesCount: 0,
    updateFavoritesCount: count => {
      this.setState({
        favoritesCount: count
      });
    },
    // flyout heart(favorites)
    flyoutFavoritesOpen: false,
    toggleFavoritesFlyout: () =>
      this.setState({ flyoutFavoritesOpen: !this.state.flyoutFavoritesOpen }),
    closeFavoritesFlyout: () => this.setState({ flyoutFavoritesOpen: false }),
    // flyout
    flyoutOpen: false,
    toggleFlyout: () => this.setState({ flyoutOpen: !this.state.flyoutOpen }),
    closeFlyout: () => this.setState({ flyoutOpen: false }),
    // modal
    modalOpen: false,
    modalType: 'login',
    modalOptions: {},
    toggleModal: (modalType, modalOptions) => {
      this.setState({
        modalOpen: !this.state.modalOpen,
        modalType,
        modalOptions: modalOptions
      });
    },

    showSignInForm: false,
    showSignUpForm: false,
    closeSignInForm: () => this.setState({ showSignInForm: false }),
    closeSignUpForm: () => this.setState({ showSignUpForm: false }),
    toggleSignInForm: () => {
      this.setState({ showSignInForm: !this.state.showSignInForm });
    },
    toggleSignUpForm: () => {
      this.setState({ showSignUpForm: !this.state.showSignUpForm });
    },
    switchForms: () => {
      this.setState({
        showSignInForm: !this.state.showSignInForm,
        showSignUpForm: !this.state.showSignUpForm
      });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    // close the modal after you login or sign up
    if (
      this.state.modalOpen &&
      !prevProps.userContext.authenticatedUser &&
      this.props.userContext.authenticatedUser
    ) {
      this.state.toggleModal();
    }
    // close flyout if user logs out
    if (
      this.state.flyoutOpen &&
      prevProps.userContext.authenticatedUser &&
      !this.props.userContext.authenticatedUser
    ) {
      this.state.toggleFlyout();
    }
  }

  render() {
    return (
      <UiStateProvider value={this.state}>
        {this.props.children}
      </UiStateProvider>
    );
  }
}

export default React.forwardRef((props, ref) => (
  <UserStateConsumer>
    {userContext => <UiState {...props} userContext={userContext} ref={ref} />}
  </UserStateConsumer>
));
