import { css } from 'styled-components';

export default css`
  -webkit-box-shadow: 0px 6px 12px 0px rgba(0,0,0,0.5);
  -moz-box-shadow: 0px 6px 12px 0px rgba(0,0,0,0.5);
  box-shadow: 0px 6px 12px 0px rgba(0,0,0,0.5);

  &.mega-open {
    height: auto;
    
  }
  top: 80px;
  .mega-open {
    h3,
    a {
      font-family: 'TradeGothic';
    }
  }
  /* position: relative; */
  /* top: 80px; */
  /* #menu-mobile {
    &.mega-open {
      position: absolute;
    }
  } */
`;
