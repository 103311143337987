import React, { Component } from 'react';
import styled from 'styled-components';
import { navigate } from '@reach/router';

import ComponentStyles from '../../brand-styles/components/Header/LanguageSelect.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

import { breakpoints, colors } from '../../style-utilities/variables';

const Button = styled.button`
  background: transparent;
  color: ${colors.primary};
  cursor: pointer;
  .Icon-desktop,
  .Icon-mobile {
    display: inline;
    font-size: 28px;
  }
  .Icon-desktop {
    height: 25px;
    width: 15px;
    position: relative;
    bottom: -3px;
    margin-right: 5px;
  }
  .Icon-mobile {
    height: 16px;
    width: 10px;
  }
  .button-text {
    font-size: 20px;
    font-weight: normal;
    display: inline;
  }
  @media (max-width: ${breakpoints.desktop}) {
    font-size: 12px;
    color: ${colors.primary};
  }

  /* Dynamic brand styles */
  ${ComponentStyles}
`;

// some logic needs to be written to determine if this site includes the language select, as most do not
class LanguageSelect extends Component {
  redirectPage = () => {
    let languageLink;
    if (this.props.languageLink) {
      languageLink = this.props.languageLink;
    }
    navigate(`${languageLink}`);
  };

  render() {
    const { viewPort, siteTitle, ...remainProps } = this.props;
    if (siteTitle && siteTitle.includes('-es')) {
      return (
        <Button {...remainProps} onClick={this.redirectPage}>
          {viewPort === 'desktop' ? (
            <>
              <span className="button-text">English</span>
              <FontAwesomeIcon icon={faAngleRight} className="Icon-desktop" />
            </>
          ) : null}{' '}
          {viewPort === 'mobile' ? (
            <>
              <span className="button-text">English</span>
              <FontAwesomeIcon icon={faAngleRight} className="Icon-mobile" />
            </>
          ) : null}{' '}
        </Button>
      );
    } else {
      return (
        <Button {...remainProps} onClick={this.redirectPage}>
          {viewPort === 'desktop' ? (
            <>
              <span className="button-text">Español</span>
              <FontAwesomeIcon icon={faAngleRight} className="Icon-desktop" />
            </>
          ) : null}{' '}
          {viewPort === 'mobile' ? (
            <>
              <span className="button-text">Español</span>
              <FontAwesomeIcon icon={faAngleRight} className="Icon-mobile" />
            </>
          ) : null}{' '}
        </Button>
      );
    }
  }
}

export default LanguageSelect;
