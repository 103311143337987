/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
// Logs when the client route changes
exports.onRouteUpdate = ({ location, prevLocation }) => {
    if(iFrameResize){
        //iFrameResize is not compatible with ancient versions of IE.
        //Cut our losses.
        if(document.querySelectorAll && Function.prototype.bind) {
            iFrameResize({checkOrigin:false});
        }
    }
  }
  