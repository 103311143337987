import { css } from 'styled-components';
import { fonts, colors, breakpoints } from '../style-utilities/variables';
export default css`
  h1 {
    font-family: ${fonts.primary}, sans-serif;
    color: ${colors.primary};
    font-size: 46px;
    @media (max-width: ${breakpoints.generalDesktop}) {
      font-size: 30px;
    }
  }
  p {
    font-family: ${fonts.secondary}, sans-serif;
  }
`;
