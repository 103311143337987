export function getAuthHeaders() {
  return {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('user-wp-token')}`
  };
}

export async function get(url) {
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: getAuthHeaders()
    });

    return response.json();
  } catch (err) {
    console.error(err);
  }
}

export async function post(url, payload) {
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: getAuthHeaders(),
      //mode: 'cors',
      body: JSON.stringify(payload)
    });

    return response.json();
  } catch (err) {
    console.error(err);
  }
}

export async function put(url, payload) {
  try {
    const response = await fetch(url, {
      method: 'PUT',
      headers: getAuthHeaders(),
      //mode: 'cors',
      body: JSON.stringify(payload)
    });

    return response.json();
  } catch (err) {
    console.error(err);
  }
}

export async function doDelete(url) {
  try {
    const response = await fetch(url, {
      method: 'DELETE',
      headers: getAuthHeaders()
    });

    return response.json();
  } catch (err) {
    console.error(err);
  }
}
