import React, { Component } from 'react';
import { navigate } from '@reach/router';
import styled from 'styled-components';
import { graphql, StaticQuery, Link } from 'gatsby';
import ComponentStyles from '../../brand-styles/components/Header/DesktopRecipes.styles';
import ReactHtmlParser from 'react-html-parser';

import RegularButton from '../RegularButton';
import Ingredient from '../../images/ingredient.svg';
import Meal from '../../images/meal.svg';
import Method from '../../images/method.svg';
import Dish from '../../images/pasta.svg';
import Time from '../../images/clock.svg';
import Sauce from '../../images/sauce.svg';
// import Main from '../../images/Mega-Main.png';
// import Occasion from '../../images/Mega-Occasion.png';
// import Health from '../../images/Mega-Health.png';
// import Ethnic from '../../images/Mega-Ethnic.png';
// import Ready from '../../images/Mega-Ready-In.png';
import { fonts, colors } from '../../style-utilities/variables';

import search from '../../images/search.png';

const Div = styled.div`
  display: grid;
  display: -ms-grid;
  padding: 40px;
  grid-template-columns: repeat(4, 1fr);
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-areas: 'col1 col2 col3 col4';
  background: white;
  .columnTwoLinks {
    display: block;
    margin-bottom: 1em;
  }
  .col-1,
  .col-2,
  .col-3,
  .col-4 {
    display: inline-block;
    text-align: left;
    height: inherit;
    width: 20vw;
    li {
      display: block;
      margin: 0;
    }
    ul {
      padding-top: 10px;
    }
    p {
      color: ${colors.bodyText};
      font-family: ${fonts.secondary};
    }
  }
  .col-1 {
    grid-area: col1;
    -ms-grid-column: 1;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 15vw;
      height: auto;
    }
  }
  .col-2 {
    grid-area: col2;
    -ms-grid-column: 2;
    li:hover {
      text-decoration: underline;
    }
  }
  .col-3 {
    grid-area: col3;
    -ms-grid-column: 3;
    a:hover,
    li:hover {
      text-decoration: underline;
    }
  }
  .col-4 {
    grid-area: col4;
    -ms-grid-column: 4;
    .search-form {
      width: 100%;
      border: 1px solid #333333;
      background: white;
      margin-bottom: 10px;
      background-image: url(${search});
      background-size: 30px;
      background-repeat: no-repeat;
      padding: 14px 12px 10px 46px;
      background-position-y: center;
      background-position-x: 2%;
    }
  }
  /* .col-5 {
    grid-area: col5;
  } */
  button {
    display: block;
    margin: 30px 0;
    width: 100%;
  }

  /* Dynamic brand styles */
  ${ComponentStyles}
`;

class DesktopRecipes extends Component {
  state = {
    term: '',
    hoverImage: ''
  };
  renderHoverImage = source => {
    this.setState({ hoverImage: source });
  };
  mouseLeaveItem = source => {
    this.setState({ hoverImage: source });
  };
  onChangeHandler = event => {
    const value = event.target.value;
    this.setState({ term: value });
  };
  submitHandler = event => {
    event.preventDefault();
    this.props.megaToggle();
    this.setState(() => {
      navigate(`/recipe-search?includes=${this.state.term}`);
    });
    event.target.reset();
  };
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            allWordpressWpTaxonomies {
              edges {
                node {
                  name
                  slug
                  types
                }
              }
            }
            allWordpressWpProductLine {
              edges {
                node {
                  name
                  slug
                  acf {
                    menu_image {
                      source_url
                    }
                  }
                }
              }
            }
            allWordpressWpAttribute1 {
              edges {
                node {
                  slug
                  name
                  acf {
                    menu_image {
                      source_url
                    }
                  }
                }
              }
            }
            allWordpressWpMainIngredient {
              edges {
                node {
                  name
                  slug
                }
              }
            }
            allWordpressWpMealCourse {
              edges {
                node {
                  name
                  slug
                }
              }
            }
            allWordpressWpReadyIn {
              edges {
                node {
                  name
                  slug
                }
              }
            }
            allWordpressWpCookingMethod {
              edges {
                node {
                  name
                  slug
                }
              }
            }
            allWordpressWpDishType {
              edges {
                node {
                  name
                  slug
                }
              }
            }
            allWordpressWpSauce {
              edges {
                node {
                  name
                  slug
                }
              }
            }
          }
        `}
        render={data => {
          /**
           * Data from CMS
           */
          const productLine = data.allWordpressWpProductLine.edges;
          const attributeOne = data.allWordpressWpAttribute1.edges;
          const initialImage = productLine[0].node.acf.menu_image.source_url;
          const mainIngredient = data.allWordpressWpMainIngredient.edges;
          const mealCourse = data.allWordpressWpMealCourse.edges;
          const readyIn = data.allWordpressWpReadyIn.edges;
          const cookingMethod = data.allWordpressWpCookingMethod.edges;
          const dishType = data.allWordpressWpDishType.edges;
          const sauce = data.allWordpressWpSauce.edges;
          // const allTaxonomies = data.allWordpressWpTaxonomies.edges;
          // const taxonomies = allTaxonomies.filter(
          //   ({ node: { types } }) => types[0] === 'product'
          // );
          const columnTwo = [
            {
              name: 'Main Ingredient',
              image: Ingredient,
              slugs: mainIngredient.map(el => el.node.slug)
            },
            {
              name: 'Meal Part',
              image: Meal,
              slugs: mealCourse.map(el => el.node.slug)
            },
            {
              name: 'Cooking Method',
              image: Method,
              slugs: cookingMethod.map(el => el.node.slug)
            },
            {
              name: 'Dish Type',
              image: Dish,
              slugs: dishType.map(el => el.node.slug)
            },
            {
              name: 'Total Time',
              image: Time,
              slugs: readyIn.map(el => el.node.slug)
            },
            {
              name: 'By Sauce',
              image: Sauce,
              slugs: sauce.map(el => el.node.slug)
            }
          ];
          /**
           * Render Categories Method
           */
          const renderRecipesByProductType = () => {
            return (
              <ul>
                {attributeOne.map(({ node: { name, slug, acf } }) => (
                  <Link
                    to={`/recipe-search?items=${slug}`}
                    onClick={this.props.megaToggle}
                    key={slug}
                  >
                    <li
                      key={slug}
                      onMouseEnter={() =>
                        this.renderHoverImage(acf.menu_image.source_url)
                      }
                      onMouseLeave={() => this.mouseLeaveItem(initialImage)}
                    >
                      {ReactHtmlParser(name)}
                    </li>
                  </Link>
                ))}
              </ul>
            );
          };
          /**
           * Render Column 2
           */
          const renderColumnTwo = () => {
            return (
              <ul>
                {columnTwo.map(item => (
                  <Link
                    key={item.name}
                    to={`/recipe-search?items=${item.slugs}`}
                    onClick={this.props.megaToggle}
                  >
                    <li
                      onMouseEnter={() => this.renderHoverImage(item.image)}
                      onMouseLeave={() => this.mouseLeaveItem(initialImage)}
                    >
                      {item.name}
                    </li>
                  </Link>
                ))}
              </ul>
            );
          };
          /**
           * Render Attribute 1 Method
           */
          //   const renderRecipesByAttributeOne = () => {
          //     return (
          //       <ul>
          //         {attributeOne.map(({ node: { name, slug } }) => (
          //           <Link
          //             to={`/recipe-search?term=${slug}`}
          //             onClick={this.props.megaToggle}
          //             key={slug}
          //           >
          //             <li key={slug}>{ReactHtmlParser(name)}</li>
          //           </Link>
          //         ))}
          //       </ul>
          //     );
          //   };
          /**
           * Render Attribute 2 Method
           */
          //   const renderRecipesByAttributeTwo = () => {
          //     return (
          //       <ul>
          //         {attributeTwo.map(({ node: { name, slug } }) => (
          //           <Link
          //             to={`/recipe-search?term=${slug}`}
          //             onClick={this.props.megaToggle}
          //             key={slug}
          //           >
          //             <li key={slug}>{ReactHtmlParser(name)}</li>
          //           </Link>
          //         ))}
          //       </ul>
          //     );
          //   };
          return (
            <Div>
              <span className="col-1">
                {this.state.hoverImage ? (
                  <img src={this.state.hoverImage} alt="menuImg" />
                ) : (
                  <img src={initialImage} alt="menuImg" />
                )}
              </span>
              <div className="col-2">
                <p>Recipes by Pasta Type</p>
                {renderRecipesByProductType()}
              </div>
              <div className="col-3">
                <p>Recipes by Category</p>
                {renderColumnTwo()}
                <Link
                  className="columnTwoLinks"
                  to="/recipe-categories"
                  onClick={this.props.megaToggle}
                >
                  View All Categories
                </Link>

                {/* <Link
                  className="columnTwoLinks"
                  to="/recipe-collections/in-the-blogosphere"
                  onClick={this.props.megaToggle}
                >
                  Blogger Recipes
                </Link> */}
              </div>
              {/* <div className="col-3">
                <h3>Recipes by Category</h3>
                {renderRecipesByAttributeTwo()}
              </div> */}
              <div className="col-4">
                <p>
                  Click below to see our curated recipe collections for every
                  occasion from Fast Weeknight Dinners to Nutritious Dishes Your
                  Kids Will Love.
                </p>
                <Link to="/recipe-collections" onClick={this.props.megaToggle}>
                  <RegularButton className="button-view-all">
                    View All Collections
                  </RegularButton>
                </Link>
                <Link to="/recipe-search" onClick={this.props.megaToggle}>
                  <RegularButton className="button-view-all">
                    View All Recipes
                  </RegularButton>
                </Link>
                <form onSubmit={this.submitHandler}>
                  <input
                    className="search-form"
                    placeholder="Search for recipes"
                    onChange={this.onChangeHandler}
                  />
                </form>
              </div>
            </Div>
          );
        }}
      />
    );
  }
}

export default DesktopRecipes;
