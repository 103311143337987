import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import ComponentStyles from '../../brand-styles/components/Header/UserWidgetMenu.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import StyledLink from '../StyledLink';

const StyledUserWidgetMenu = styled.ul`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-evenly;
  padding-left: 0;
  padding: 10px;
  @media (-ms-high-contrast: none) {
    /* IE only */
    justify-content: space-around;
  }
  @supports (-ms-ime-align: auto) {
    /* Edge only */
    justify-content: space-around;
  }

  a {
    text-decoration: none;
    :hover {
      opacity: 0.9;
    }
  }
  .styled-link {
    padding: 0;
    width: 100%;
  }
  button {
    padding: 0;
    background: transparent;
    color: white;
    width: 0;
    border: none;
  }
  /* Dynamic brand styles */
  ${ComponentStyles}
`;

const MenuItem = styled.li`
  list-style: none;
  text-decoration: none;
  font-size: 16px;
  &:nth-child(2) {
    padding: 4px 0;
  }
  &:nth-child(3) {
    padding-bottom: 8px;
  }
`;

const UserWidgetMenu = ({ context, uiStateContext, toggleMenu }) => {
  return (
    <StyledUserWidgetMenu>
      <MenuItem>
        <Link
          to="/user/profile"
          onClick={() => {
            uiStateContext.closeFlyout();
            toggleMenu();
          }}
        >
          My Profile
        </Link>
      </MenuItem>
      <MenuItem>
        <Link
          to="/user/lists"
          onClick={() => {
            uiStateContext.closeFlyout();
            toggleMenu();
          }}
        >
          Saved Shopping List
        </Link>
      </MenuItem>
      <MenuItem>
        <Link
          to="/user/favorites"
          onClick={() => {
            uiStateContext.closeFlyout();
            toggleMenu();
          }}
        >
          Favorites
        </Link>
      </MenuItem>
      <MenuItem>
        <StyledLink link="/">
          <button
            onClick={() => {
              context.logout();
              uiStateContext.closeFlyout();
              uiStateContext.closeSignInForm();
            }}
          >
            Log Out
          </button>
        </StyledLink>
      </MenuItem>
    </StyledUserWidgetMenu>
  );
};

export default UserWidgetMenu;
