import { css } from 'styled-components';

export default css`
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        background: -moz-linear-gradient(left, rgba(147,130,77,1) 0%, rgba(253,216,142,1) 51%, rgba(147,130,77,1) 100%);
        background: -webkit-gradient(left top, right top, color-stop(0%, rgba(147,130,77,1)), color-stop(51%, rgba(253,216,142,1)), color-stop(100%, rgba(147,130,77,1)));
        background: -webkit-linear-gradient(left, rgba(147,130,77,1) 0%, rgba(253,216,142,1) 51%, rgba(147,130,77,1) 100%);
        background: -o-linear-gradient(left, rgba(147,130,77,1) 0%, rgba(253,216,142,1) 51%, rgba(147,130,77,1) 100%);
        background: -ms-linear-gradient(left, rgba(147,130,77,1) 0%, rgba(253,216,142,1) 51%, rgba(147,130,77,1) 100%);
        background: linear-gradient(to right, rgba(147,130,77,1) 0%, rgba(253,216,142,1) 51%, rgba(147,130,77,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#93824d', endColorstr='#93824d', GradientType=1 );
    }
`;
