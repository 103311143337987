import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import ComponentStyles from '../../brand-styles/components/Footer/FooterNav.styles';
import { breakpoints } from '../../style-utilities/variables';

const StyledFooterNav = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  li {
    cursor: pointer;
    padding-top: 8px;
    margin: 5px 15px;
  }
  li.wildcard {
    display: none;
  }
  li:hover {
    opacity: 0.6;
  }
  @media (min-width: ${breakpoints.tablet}) {
    justify-content: space-evenly;
    flex-direction: row;
    li {
      display: inline-block;
      text-align: left;
    }
  }
  @media (-ms-high-contrast: none) {
    justify-content: space-around;
  }

  /* Dynamic Brand Styles */
  ${ComponentStyles}
`;
const FooterNav = () => {
  return (
    <StyledFooterNav>
      <li className="products">
        <Link to="/products">Products</Link>
      </li>
      <li className="recipes">
        <Link to="/recipe-search">Recipes</Link>
      </li>
      <li className="about">
        <Link to="/about">About</Link>
      </li>
      <li className="wtb">
        <Link to="/where-to-buy">Where to Buy</Link>
      </li>
    </StyledFooterNav>
  );
};

export default FooterNav;
