import React from 'react';
import styled from 'styled-components';
//Components
import TopFooter from './TopFooter';
import FooterGradient from './FooterGradient';
import BottomFooter from './BottomFooter';
//Styles from Different Brands
import ComponentStyles from '../../brand-styles/components/Footer/Footer.styles';

const Div = styled.div`
  color: white;
  position: relative;
  z-index: 1;

  ${ComponentStyles}
`;

const Footer = ({ siteLogo, socialLinks, siteTitle }) => {
  return (
    <Div className="footer-wrap">
      <TopFooter siteLogo={siteLogo} socialLinks={socialLinks} />
      <FooterGradient />
      <BottomFooter siteTitle={siteTitle} />
    </Div>
  );
};

export default Footer;
