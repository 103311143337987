import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import ComponentStyles from '../../brand-styles/components/Footer/FooterLogo.styles';

const StyledLogo = styled(Link)`
  grid-area: FooterLogo;
  cursor: pointer;

  img {
    max-width: 150px;
    margin: 0 auto;
  }

  /* Dynamic Brand Styles */
  ${ComponentStyles}
`;

export default function FooterLogo({ siteLogo, altText }) {
  return (
    <StyledLogo to="/">
      <img src={siteLogo} alt={altText} />
    </StyledLogo>
  );
}
