import { css } from 'styled-components';

export default css`
  li {
    margin-left: 1.75em;
  }
  .pinterestLink,
  .twitterLink {
    display: none;
  }
  .facebookLink {
    margin-left: 0.75em;
  }
`;
