import React from 'react';
import styled from 'styled-components';
import ComponentStyles from '../brand-styles/components/Flyout.styles';
import { UiStateConsumer } from '../context/UiStateContext';
import { breakpoints, colors } from '../style-utilities/variables';

const StyledFlyout = styled.div`
  background: white;
  box-shadow: 1px 2px 10px #aaa;
  display: none;
  height: 150px;
  position: absolute;
  left: -50px;
  top: 48px;
  width: 220px;
  z-index: 4;
  @media (min-width: 450px) {
    left: -13px;
  }
  @media (min-width: 900px) {
    left: -60px;
  }
  &.show {
    display: block;
    .button-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 150px;
      padding: 10px;
    }
    button {
      padding: 12px 30px;
      width: 180px;
      margin: 0 auto;
      display: block;
      :hover {
        opacity: 0.8;
        transition: opacity 0.3s ease-out;
      }
    }
    .flyout-login {
      border: none;
      background: ${colors.primary};
      color: white;
      margin-bottom: 14px;
      &:hover {
        background-color: ${colors.secondary};
        transition: background-color 0.3s ease-out;
      }
    }
    .flyout-signup {
      background: white;
      background: none;
      color: ${colors.primary};
      border: 1px solid ${colors.primary};
      &:hover {
        color: white;
        background-color: ${colors.primary};
        transition: background-color 0.3s ease-out;
      }
    }
  }

  .caret {
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid white;
    height: 0;
    position: absolute;
    left: 62px;
    top: -12px;
    width: 0;
    @media (min-width: 450px) {
      left: 25px;
    }
    @media (min-width: 900px) {
      left: 75px;
    }
  }

  /* Dynamic brand styles */
  ${ComponentStyles}
`;

const Flyout = ({ children, styles }) => {
  return (
    <UiStateConsumer>
      {context => {
        return (
          <StyledFlyout
            className={context.flyoutOpen ? 'show' : ''}
            {...styles}
          >
            <div className="caret" />
            <div className="button-container">{children}</div>
          </StyledFlyout>
        );
      }}
    </UiStateConsumer>
  );
};

export default Flyout;
