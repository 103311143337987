import React, { Component } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import ComponentStyles from '../../brand-styles/components/Header/FavoritesLink.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { breakpoints } from '../../style-utilities/variables';
import FlyoutFavorites from '../FlyoutFavorites';
import UserWidgetMenu from './UserWidgetMenu';
import FavoritesService from '../../services/favorites.service';
import UserService from '../../services/user.service';
import { UiStateConsumer, UiStateContext } from '../../context/UiStateContext';
import { UserStateConsumer } from '../../context/UserStateContext';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';

const Button = styled.button`
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  color: white;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
  /* Dynamic brand styles */
  ${ComponentStyles}
`;

class FavoritesLink extends Component {
  constructor(props) {
    super(props);
    this.favoriteService = new FavoritesService();
    this.userService = new UserService();
  }
  state = {
    viewPort: 'desktop',
    isBrowser: true
  };
  componentWillUnmount() {
    if (this.state.isBrowser) {
      window.removeEventListener('resize', this.setViewport);
    }
    document.removeEventListener('click', this.handleClick, false);
  }

  setViewport = () => {
    this.setState({
      viewPort:
        this.state.isBrowser && window.innerWidth >= 900 ? 'desktop' : 'mobile'
    });
  };

  componentDidMount() {
    const user = this.userService.getAuthenticatedUser();
    const type = 'recipes';
    if (user) {
      this.props.userStateContext.updateAuthenicatedUser(user);
      this.favoriteService.getFavoritesForUser(user, type).then(result => {
        if (result) {
          this.props.userStateContext.updateFavoritesCount(result.length);
        }
      });
    }
    this.setState({
      isBrowser: typeof window !== 'undefined',
      viewPort: window.innerWidth >= 900 ? 'desktop' : 'mobile'
    });
    if (this.state.isBrowser) {
      window.addEventListener('resize', this.setViewport);
    }

    document.addEventListener('click', this.handleClick, false);
  }

  handleClick = e => {
    const user = this.userService.getAuthenticatedUser();
    if (!user && this.node) {
      if (this.node.contains(e.target)) {
        return;
      }
      this.context.closeFavoritesFlyout();
    }
  };

  render() {
    return (
      <UiStateConsumer>
        {uiStateContext => {
          return (
            <UserStateConsumer>
              {userStateContext => {
                const user = userStateContext.authenticatedUser;
                return user ? (
                  <div ref={node => (this.node = node)} className="fav-div">
                    <Link to="/user/favorites">
                      <FontAwesomeIcon
                        className="heartIcon"
                        onClick={this.props.toggle}
                        icon={faHeart}
                        style={{ width: '42px', height: '26px' }}
                      />
                    </Link>
                    {this.props.userStateContext.favoritesCount ? (
                      <div className="favorite-count">
                        {this.props.userStateContext.favoritesCount || ''}
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <div ref={node => (this.node = node)} className="fav-div">
                    <Button onClick={uiStateContext.toggleFavoritesFlyout}>
                      <FontAwesomeIcon
                        className="heartIconNoAuth"
                        icon={faHeart}
                        style={{ width: '42px', height: '26px' }}
                      />
                    </Button>
                    <FlyoutFavorites siteTitle={this.props.siteTitle}>
                      <div>
                        <button
                          className="flyout-login"
                          onClick={() => uiStateContext.toggleModal('login')}
                        >
                          Log In
                        </button>
                      </div>
                      <div>
                        <button
                          className="flyout-signup"
                          onClick={() => uiStateContext.toggleModal('signUp')}
                        >
                          Sign Up
                        </button>
                      </div>
                    </FlyoutFavorites>
                  </div>
                );
              }}
            </UserStateConsumer>
          );
        }}
      </UiStateConsumer>
    );
  }
}
FavoritesLink.contextType = UiStateContext;

export default FavoritesLink;
