import React, { Component } from 'react';
import styled from 'styled-components';
import ComponentStyles from '../../brand-styles/components/Header/UserWidget.styles';
import { UiStateConsumer } from '../../context/UiStateContext';
import { UserStateConsumer } from '../../context/UserStateContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';

import { breakpoints } from '../../style-utilities/variables';
import Flyout from '../Flyout';
import UserWidgetMenu from './UserWidgetMenu';

const Button = styled.button`
  display: flex;
  align-items: center;
  margin-right: 0.5em;
  height: 100%;
  border: none;
  background: transparent;
  cursor: pointer;
  position: relative;
  color: white;
  .user-icon {
    margin: 0 5px 0 0;
    font-size: 28px;
  }
  .sign-in {
    font-weight: normal;
  }
  span {
    margin-top: 4px;
    text-decoration: none;
  }
`;

const StyledUserWidget = styled.div`
  position: relative;
  .user_avatar {
    border-radius: 24px;
    margin-right: 0.3em;
  }

  ${Button} /* Dynamic brand styles */
  ${ComponentStyles}
`;

class UserWidget extends Component {
  state = {
    viewPort: 'desktop',
    isBrowser: true
  };

  componentWillUnmount() {
    if (this.state.isBrowser) {
      window.removeEventListener('resize', this.setViewport);
    }
  }

  componentDidMount() {
    this.setState({
      isBrowser: typeof window !== 'undefined',
      viewPort: window.innerWidth >= 900 ? 'desktop' : 'mobile'
    });
    if (this.state.isBrowser) {
      window.addEventListener('resize', this.setViewport);
    }
  }

  setViewport = () => {
    this.setState({
      viewPort:
        this.state.isBrowser && window.innerWidth >= 900 ? 'desktop' : 'mobile'
    });
  };

  renderAvatar = user => {
    // checks for google auth profile object
    if (user !== null && user.auth_type === 'google') {
      return (
        <img
          style={{ borderRadius: '50%', marginRight: '0.4rem' }}
          src={user.profileObj.imageUrl || ''}
          className="userProfile-img"
          alt="userPro"
        />
      );
    } else if (user !== null && user.auth_type === 'facebook') {
      return (
        <img
          style={{ borderRadius: '50%', marginRight: '0.4rem' }}
          src={user.picture.data.url || ''}
          className="userProfile-img"
          alt="userPro"
        />
      );
    } else if (user !== null && user.auth_type === 'form') {
      return (
        <img
          style={{ borderRadius: '50%', marginRight: '0.4rem' }}
          src={user.avatar_urls['96'] || ''}
          className="userProfile-img"
          alt="userPro"
        />
      );
    }
  };

  render() {
    return (
      <UiStateConsumer>
        {uiStateContext => {
          return (
            <StyledUserWidget>
              <UserStateConsumer>
                {userStateContext => {
                  const user = userStateContext.authenticatedUser;
                  return user ? (
                    <>
                      <Button
                        onClick={uiStateContext.toggleFlyout}
                        id="user-widget-btn"
                      >
                        {this.renderAvatar(user)}
                        {/* display first and last name */}
                        <span>{user.first_name}</span>
                      </Button>
                      <Flyout>
                        <UserWidgetMenu
                          context={userStateContext}
                          uiStateContext={uiStateContext}
                          toggleMenu={this.props.toggle}
                        />
                      </Flyout>
                    </>
                  ) : (
                    <>
                      {this.state.viewPort === 'desktop' ? (
                        // Removed Flyout Login and Sign Up button functionality for UX puroposes
                        // <Button onClick={uiStateContext.toggleFlyout}>
                        <Button
                          onClick={() => uiStateContext.toggleModal('login')}
                        >
                          <FontAwesomeIcon
                            className="user-icon"
                            icon={faUserCircle}
                            style={{ width: '28px', height: '28px' }}
                          />
                          <span>Sign In</span>
                        </Button>
                      ) : (
                        <Button onClick={uiStateContext.toggleSignInForm}>
                          <FontAwesomeIcon
                            className="user-icon"
                            icon={faUserCircle}
                            style={{ width: '28px', height: '28px' }}
                          />
                          <span className="sign-in">Sign In</span>
                        </Button>
                      )}
                    </>
                  );
                }}
              </UserStateConsumer>
            </StyledUserWidget>
          );
        }}
      </UiStateConsumer>
    );
  }
}

export default UserWidget;
