import React, { Component } from 'react';
import styled from 'styled-components';
import ComponentStyles from '../../brand-styles/components/Forms/SignUpForm.styles';
import ForgotPasswordService from '../../services/forgotPassword.service';
import { validateEmail } from '../../utilities/validationUtils';

import RegularButton from '../../components/RegularButton';

const StyledSignupForm = styled.div`
  display: flex;

  .img-container {
    flex: 1 55%;

    img {
      height: 100%;
      max-width: 100%;
      object-fit: cover;
    }
  }

  .content {
    padding: 5%;
    flex: 1 45%;
  }

  .error {
    color: red;
  }

  /* Dynamic brand styles */
  ${ComponentStyles}
`;

function validate(state) {
  const errors = {};

  if (!state.email) {
    errors.email = 'Email is required';
  } else if (state.email.length < 3) {
    errors.email = 'Email must be at least 3 characters long';
  } else if (!validateEmail(state.email)) {
    errors.email = 'Must be a valid email address';
  }
  return errors;
}

class ForgotPasswordForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      showForm: true,
      errors: {},
      hasBeenSubmitted: false,
      isSubmitting: false
    };

    this.forgotPasswordService = new ForgotPasswordService();
  }

  handleInputChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState(
      {
        [name]: value
      },
      () => {
        if (this.state.hasBeenSubmitted) {
          const errors = validate(this.state);
          this.setState({
            errors
          });
        }
      }
    );
  };

  submit = () => {
    const errors = validate(this.state);
    if (Object.keys(errors).length) {
      this.setState({
        errors: errors,
        hasBeenSubmitted: true
      });
    } else {
      this.setState({
        errors: errors,
        hasBeenSubmitted: true,
        isSubmitting: true
      });

      this.forgotPasswordService.checkUser(this.state.email).then(
        existing => {
          if (existing && existing.status === false) {
            this.setState({
              errors: {
                email: 'User does not exist.'
              },
              isSubmitting: false
            });
          } else {
            this.forgotPasswordService.sendForgotPasswordEmail(existing).then(
              () => {
                this.setState({
                  showForm: false,
                  isSubmitting: false
                });
              },
              error => {
                console.error(error);

                this.setState({
                  errors: {
                    email: 'Error sending email.'
                  },
                  isSubmitting: false
                });
              }
            );
          }
        },
        error => {
          console.error(error);

          this.setState({
            errors: {
              email: 'Error checking user email.'
            },
            isSubmitting: false
          });
        }
      );
    }
  };

  render() {
    return (
      <StyledSignupForm>
        <div className="content">
          {this.state.showForm ? (
            <div>
              <h3>Please enter your email address.</h3>
              <div className="input-group">
                <input
                  name="email"
                  id="email"
                  type="text"
                  placeholder="Email"
                  value={this.state.value}
                  onChange={this.handleInputChange}
                />
              </div>
              {this.state.errors.email && (
                <p className="error">{this.state.errors.email}</p>
              )}
              <RegularButton
                type="button"
                onClick={this.submit}
                disabled={this.state.isSubmitting}
              >
                Submit
              </RegularButton>
            </div>
          ) : (
            <p>
              Success! An email has been sent to you to reset your password.
            </p>
          )}
        </div>
      </StyledSignupForm>
    );
  }
}

export default ForgotPasswordForm;
