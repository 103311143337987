import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import ComponentStyles from '../brand-styles/components/StyledLink.styles.js';
import { colors } from '../style-utilities/variables';

const Button = styled(Link)`
  color: white;
  background: ${colors.primary};
  font-size: 20px;
  padding: 12px 30px;
  text-decoration: none;
  display: inline-block;
  &:hover {
    opacity: 0.9;
    transition: opacity 0.3s ease-out;
  }
  /* Dynamic brand styles */
  ${ComponentStyles}
`;

const StyledLink = ({ link, children, ...remainProps }) => {
  return (
    <Button to={link} className="styled-link" {...remainProps}>
      {children}
    </Button>
  );
};

export default StyledLink;
