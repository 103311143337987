import React, { Component } from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql, Link } from 'gatsby';
import ReactHtmlParser from 'react-html-parser';
import ComponentStyles from '../../brand-styles/components/Header/MobileProducts.styles';
import { breakpoints, colors, fonts } from '../../style-utilities/variables';
import StyledLink from '../../components/StyledLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeft,
  faMinus,
  faPlus
} from '@fortawesome/free-solid-svg-icons';
import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

const Div = styled.div`
  /* position: relative; */
  height: 100vh;
  overflow: scroll !important;
  background: ${colors.white};
  .title {
    font-size: 20px;
    color: ${colors.secondary};
  }
  svg {
    display: block;
  }
  .accordion-body {
    padding: 0;
    border: none;
    li {
      color: ${colors.secondary};
      border: none;
    }
  }

  .back {
    font-size: 20px;
    color: ${colors.secondary};
    outline: none;
  }
  .accordion__title {
    /* outline: none; */
    strong {
      padding: 20px 15px;
    }
  }

  .accordion__plusMinus {
    top: 0;
    float: right;
    margin-top: 0;
    position: relative;
  }
  .subtopic,
  .hideBodyTitle {
    border: 1px solid ${colors.white};
    background: ${colors.secondary} !important;
    color: ${colors.white};
    padding: 15px 10px;
  }
  .topicTitle {
    font-size: 18px;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 0;

    font-family: ${fonts.secondaryMedium};
    color: ${colors.white};
    @media (min-width: ${breakpoints.tablet}) {
      font-size: 20px;
    }
  }
  ul {
    padding: 0;
  }
  .icon {
    position: relative;
    float: right;
  }
  .plus {
    display: none;
    transition-duration: 0.2s;
    transition-property: height;
  }
  .minus {
    display: inline;
    transition-duration: 0.2s;
    transition-property: height;
  }
  .hideBodyTitle {
    .plus {
      display: inline;
    }
    .minus {
      display: none;
    }
  }
  .not-sure {
    margin: 30px auto 10px auto;
    font-size: 20px;
    text-align: center;
  }
  .view-buttons {
    text-align: center;
    .view-all {
      color: ${colors.white};
      background: ${colors.secondary};
    }
  }

  /* Dynamic brand styles */
  ${ComponentStyles}
`;

class MobileProducts extends Component {
  state = {
    activeAccordion: 1
  };

  handleAccordionChange = clickedAccordion => {
    this.setState({ activeAccordion: clickedAccordion });
  };

  render() {
    const { megaToggle, mainNavToggle, siteType } = this.props;
    return (
      <StaticQuery
        query={graphql`
          query {
            allWordpressWpTaxonomies {
              edges {
                node {
                  name
                  slug
                  types
                }
              }
            }
            allWordpressWpProductLine {
              edges {
                node {
                  name
                  slug
                }
              }
            }
            allWordpressWpAttribute1 {
              edges {
                node {
                  slug
                  name
                }
              }
            }
          }
        `}
        render={data => {
          //Data from CMS
          const productLine = data.allWordpressWpProductLine.edges;
          const attributeOne = data.allWordpressWpAttribute1.edges;
          const allTaxonomies = data.allWordpressWpTaxonomies.edges;
          const productTaxonomies = allTaxonomies.filter(
            ({ node: { types } }) => types[0] === 'product'
          );
          /**
           * Render Product Categories Method
           */
          const renderProductLine = () => {
            return (
              <ul>
                {productLine.map(({ node: { name, slug } }) => (
                  <Link
                    to={`/product-categories/${slug}`}
                    onClick={() => {
                      mainNavToggle();
                      megaToggle();
                    }}
                    key={slug}
                  >
                    <li key={slug}>{ReactHtmlParser(name)}</li>
                  </Link>
                ))}
              </ul>
            );
          };
          /**
           * Render Attribute 1 Method
           */
          const renderAttributeOne = () => {
            return (
              <ul>
                {attributeOne.map(({ node: { name, slug } }) => (
                  <Link
                    to={`/products?option=${slug}`}
                    onClick={() => {
                      mainNavToggle();
                      megaToggle();
                    }}
                    key={slug}
                  >
                    <li key={slug}>{ReactHtmlParser(name)}</li>
                  </Link>
                ))}
              </ul>
            );
          };
          const productsMenu = [
            {
              id: 1,
              title: 'Pasta Line',
              list: renderProductLine()
            },
            {
              id: 2,
              title: 'Pasta Type',
              list: renderAttributeOne()
            }
          ];
          return (
            <Div>
              <button className="back-button" onClick={megaToggle}>
                <FontAwesomeIcon className="back" icon={faArrowLeft} />
              </button>
              <div className="title">
                <strong>Products</strong>
              </div>

              {productsMenu.map(item => {
                return (
                  <Accordion
                    key={item.id}
                    onClick={() => this.handleAccordionChange(item.id)}
                  >
                    <AccordionItem
                      expanded={this.state.activeAccordion === item.id}
                    >
                      <AccordionItemTitle
                        className="subtopic"
                        hideBodyClassName="hideBodyTitle"
                      >
                        <h3 className="topicTitle">{ReactHtmlParser(item.title)}</h3>
                        <div className="accordion__plusMinus plus">
                          <FontAwesomeIcon id="icon" icon={faPlus} />
                        </div>
                        <div className="accordion__plusMinus minus">
                          <FontAwesomeIcon id="icon" icon={faMinus} />
                        </div>
                      </AccordionItemTitle>

                      <AccordionItemBody className="accordion-body">
                        {item.list}
                      </AccordionItemBody>
                    </AccordionItem>
                  </Accordion>
                );
              })}
              <div className="not-sure">Not Sure?</div>
              <div className="view-buttons">
                <StyledLink
                  className="view-all"
                  link="/products"
                  onClick={() => {
                    mainNavToggle();
                    megaToggle();
                  }}
                >
                  View All Products
                </StyledLink>
              </div>
            </Div>
          );
        }}
      />
    );
  }
}

export default MobileProducts;
