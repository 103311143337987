import { css } from 'styled-components';

export default css`
  .google {
    padding: 15px 0 15px 30px;
    text-align: left;
    svg {
      width: 50px;
    }
  }
`;
