import { css } from 'styled-components';

export default css`
  .close-button {
    position: relative;
    left: 140px;
    border: none;
    background-color: transparent;
  }
  .forgot {
    border: none;
    background-color: transparent;
  }
`;
