import React, { Component } from 'react';
// import { navigate } from '@reach/router';
import { navigate } from 'gatsby';
// context
const SearchStateContext = React.createContext();
const SearchStateProvider = SearchStateContext.Provider;
export const SearchStateConsumer = SearchStateContext.Consumer;

class SearchState extends Component {
  BASE_URL = `${process.env.WP_BASE_URI}/${
    process.env.ACTIVE_BRAND
  }/wp-json/wp/v2/`;

  state = {
    requestPending: false,
    searchTerm: '',
    searchResults: { recipes: [], products: [] },
    updateSearchTerm: async searchTerm => {
      await this.setState({
        searchTerm
      });
    },
    searchSubmit: async () => {
      // start loading indicator
      this.setState({
        requestPending: true
      });

      const results = await this.searchWordpress(this.state.searchTerm);

      this.setState(
        {
          searchResults: {
            recipes: [...results[0]],
            products: [...results[1]],
            other: [...results[2]]
          },
          totalResults:
            results[0].length + results[1].length + results[2].length,
          requestPending: false
        },
        () => {
          navigate(`/search-results?term=${this.state.searchTerm}`);
        }
      );
    }
  };

  async searchWordpress(searchTerm) {
    // use Promise.all to make requests for different post types
    const searchMultiplePostTypes = await Promise.all([
      fetch(`${this.BASE_URL}recipes?per_page=100&search=${searchTerm}`),
      fetch(`${this.BASE_URL}products?per_page=100&search=${searchTerm}`),
      fetch(`${this.BASE_URL}pages?per_page=100&search=${searchTerm}`)
    ]);
    // send back arrays of results
    return await Promise.all(searchMultiplePostTypes.map(res => res.json()));
  }

  render() {
    return (
      <SearchStateProvider value={this.state}>
        {this.props.children}
      </SearchStateProvider>
    );
  }
}

export default SearchState;
