const stripSpaceRegex = /^\s+|\s+$/g;

export function validateEmail(email) {
  let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
export function validateName(name) {
  let pattern = /^(?=.{1,255}$)[a-z]+(?:['_.\s][a-z]+)*$/i;
  return pattern.test(name.replace(stripSpaceRegex, ''));
}
export function validatePhone(phone) {
  const phonePattern = /^[2-9]\d{2}-\d{3}-\d{4}$/i;
  return phonePattern.test(phone);
}
export function validateComment(comment) {
  const commentPattern = /^[\s\S]{1,5000}$/;
  return commentPattern.test(comment.replace(stripSpaceRegex, ''));
}
export function validateVideoLink(link) {
  const urlPattern = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/i;
  return urlPattern.test(link.replace(stripSpaceRegex, ''));
}
export function validateRecipeTitle(title) {
  const titlePattern = /^[\s\S]{1,500}$/;
  return titlePattern.test(title.replace(stripSpaceRegex, ''));
}
export function validateAddress(address) {
  const addressPattern = /^\s*\S+(?:\s+\S+){2}/;
  return addressPattern.test(address.replace(stripSpaceRegex, ''));
}
export function validateCity(city) {
  const cityPattern = /^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$/;
  return cityPattern.test(city.replace(stripSpaceRegex, ''));
}
export function validateZip(zip) {
  const zipPattern = /^\d{5}(?:[-\s]\d{4})?$/;
  return zipPattern.test(zip.replace(stripSpaceRegex, ''));
}
export function validateSize(fileSize) {
  return fileSize < 60000000;
}
export function validateType(fileType) {
  const typePattern = /\.(jpeg|jpg|png|doc|pdf|docx)$/i;
  return typePattern.exec(fileType);
}
