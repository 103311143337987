import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import ComponentStyles from '../../brand-styles/components/Header/MainLogo.styles';

const StyledMainLogo = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 65px;
  img {
    max-width: 100%;
  }

  /* Dynamic brand styles */
  ${ComponentStyles}
`;

const MainLogo = ({ siteLogo, altText, closeMenuOnSearch }) => {
  return (
    <StyledMainLogo
      to="/"
      onClick={() => {
        if (closeMenuOnSearch) {
          closeMenuOnSearch();
        }
      }}
    >
      <img src={siteLogo} alt={altText} />
    </StyledMainLogo>
  );
};

export default MainLogo;
