import React, { Component } from 'react';
import { UiStateConsumer } from '../../context/UiStateContext';
import { UserStateConsumer } from '../../context/UserStateContext';
import { Form, Text } from 'informed';
import styled from 'styled-components';
import ComponentStyles from '../../brand-styles/components/Forms/LoginForm.styles';
import FacebookAuth from './FacebookAuth';
import GoogleAuth from './GoogleAuth';
import RegularButton from '../RegularButton';
import WhiteButton from '../WhiteButton';
import { layer } from '@fortawesome/fontawesome-svg-core';
import LogInLady from '../../images/womanloggingin.jpg';
import LogInLadyTwo from '../../images/BowlsRecipeCollection-020-V2.jpg';

const StyledLoginForm = styled.div`
  display: flex;
  .account-warning {
    font-size: 0.8rem;
    padding: 0.8rem;
    background-color: #e4f7ff;
  }
  .img-container {
    flex: 1 55%;

    img {
      height: 100%;
      max-width: 100%;
      object-fit: cover;
    }
  }
  #email {
    margin-bottom: 10px;
  }
  .content {
    padding: 2% 5%;
    flex: 1 45%;
  }
  .forgot {
    background: transparent;
    border: none;
    text-decoration: underline;
  }
  .button-link {
    text-decoration: underline;
    border: none;
    background: transparent;
  }
  .facebook,
  .google {
    padding: 15px 0px 15px 30px;
    text-align: left;
    svg {
      width: 50px;
    }
  }
  /* Dynamic brand styles */
  ${ComponentStyles}
`;

const ErrorMessage = styled.span`
  color: red;
  display: none;

  &.show {
    display: block;
  }
`;

class LoginForm extends Component {
  fields = [
    {
      name: 'email',
      type: 'text',
      placeholder: 'Email'
    },
    {
      name: 'password',
      type: 'password',
      placeholder: 'Password'
    }
  ];

  renderInputs = () =>
    this.fields.map(field => (
      <div key={field.name} className="input-group">
        <Text
          placeholder={field.placeholder}
          field={field.name}
          id={field.name}
          type={field.type}
        />
      </div>
    ));

  render() {
    return (
      <UiStateConsumer>
        {uiStateContext => {
          return (
            <StyledLoginForm style={this.props.propStyles}>
              {this.props.showImage && (
                <div className="img-container">
                  <img
                    src={
                      this.props.siteTitle === 'success'
                        ? LogInLadyTwo
                        : LogInLady
                    }
                    alt="woman using tablet"
                  />
                </div>
              )}

              <UserStateConsumer>
                {userContext => (
                  <div className="content">
                    <h2>Log In</h2>
                    <div className="input-group">
                      <FacebookAuth
                        authenticateUser={user =>
                          userContext.authenticateUser('facebook', user)
                        }
                      />
                    </div>
                    <div className="input-group">
                      <GoogleAuth
                        authenticateUser={user =>
                          userContext.authenticateUser('google', user)
                        }
                      />
                    </div>
                    <p className="or">Or</p>
                    <Form
                      onSubmit={user => {
                        userContext.authenticateUser('form', user);
                      }}
                    >
                      {this.renderInputs()}
                      <p>
                        <button
                          type="button"
                          className="forgot"
                          onClick={() => {
                            uiStateContext.toggleModal();
                            setTimeout(() => {
                              uiStateContext.toggleModal('forgotpassword');
                            }, 0);
                          }}
                        >
                          {' '}
                          Forgot Password
                        </button>
                      </p>
                      <RegularButton type="submit">
                        {userContext.requestPending
                          ? 'Logging you in...'
                          : 'Log In'}
                      </RegularButton>
                    </Form>

                    <p>
                      Don't have an account?{' '}
                      <button
                        className="button-link"
                        type="button"
                        onClick={() => {
                          uiStateContext.toggleModal();
                          setTimeout(() => {
                            uiStateContext.toggleModal('signUp');
                          }, 100);
                        }}
                      >
                        Sign Up
                      </button>
                    </p>

                    <div className="errors">
                      {userContext.errors.map(error => {
                        if (error.message === 'Incorrect username...') {
                          error.message =
                            'Incorrect email or password. Try again';
                        } else if (
                          error.message ===
                          'Username already exists, please enter another username'
                        ) {
                          error.message =
                            'Email already exists. Did you sign up through Facebook or Google?';
                        }
                        return (
                          <div
                            key={`${error.field}-${error.message}`}
                            className="error-message"
                            style={{ color: 'red' }}
                          >
                            {error.message}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </UserStateConsumer>
            </StyledLoginForm>
          );
        }}
      </UiStateConsumer>
    );
  }
}

export default LoginForm;
