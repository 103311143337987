import { get, post, put, doDelete } from '../utilities/fetchUtils';

export default class ShoppingListService {
  BASE_URL = `${process.env.RIVIANA_BACKEND_URI}/api/shoppinglist`;
  ACTIVE_BRAND = process.env.ACTIVE_BRAND;

  getShoppingListForUser = async user => {
    const brand = this.ACTIVE_BRAND;
    const userId = user.id;

    const url = `${this.BASE_URL}/${brand}/${userId}`;

    return get(url);
  };

  emailShoppingListForUser = async (user, email) => {
    const brand = this.ACTIVE_BRAND;
    const userId = user.id;

    const payload = {
      brand,
      userId,
      email
    };

    const url = `${this.BASE_URL}/email`;

    return post(url, payload);
  };

  addShoppingListItem = async (user, item) => {
    const payload = {
      brand: this.ACTIVE_BRAND,
      userId: user.id,
      name: item.name,
      quantity: item.quantity,
      unit: item.unit
    };

    return post(this.BASE_URL, payload);
  };

  addShoppingListItems = async (user, items) => {
    let promises = [];

    if (items && items.length) {
      items.forEach(item => {
        promises.push(this.addShoppingListItem(user, item));
      });
    }

    return Promise.all(promises);
  };

  updateShoppingListItem = async (user, item) => {
    const payload = {
      brand: this.ACTIVE_BRAND,
      userId: user.id,
      ...item
    };

    return put(this.BASE_URL, payload);
  };

  deleteShoppingListItem = async (user, name) => {
    const brand = this.ACTIVE_BRAND;
    const userId = user.id;

    const url = `${this.BASE_URL}/${brand}/${userId}/${encodeURIComponent(
      name
    )}`;

    return doDelete(url);
  };
}
