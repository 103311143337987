import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import ComponentStyles from '../brand-styles/components/RegularButton.styles';
import { colors } from '../../src_american-beauty/style-utilities/variables';

export const Button = styled.button`
  background-color: ${colors.primary};
  border: none;
  padding: 12px 30px;
  margin-top: 12px;
  font-size: 20px;
  color: white;
  text-decoration: none;
  display: inline-block;
  outline: none;
  &:hover {
    background-color: ${colors.secondary};
    transition: background-color 0.3s ease-out;
  }
  &:disabled {
    background-color: gray;
    &:hover {
      background-color: gray;
    }
  }

  /* Dynamic brand styles */
  ${ComponentStyles}
`;

const RegularButton = ({ link, children, ...remainProps }) => {
  return <Button {...remainProps}>{children}</Button>;
};

export default RegularButton;
