import { get, post } from '../utilities/fetchUtils';

export default class FavoritesService {
  FAVORITES_URL = `${process.env.RIVIANA_BACKEND_URI}/api/favorites`;
  WP_URL = process.env.WP_BASE_URI;
  ACTIVE_BRAND = process.env.ACTIVE_BRAND;

  removeFavorite = async (user, item, type) => {
    const payload = {
      userId: user.id,
      slug: item.slug,
      brand: this.ACTIVE_BRAND,
      type
    };

    return post(this.FAVORITES_URL, payload);
  };

  toggleFavorite = async (user, item, type) => {
    const payload = {
      userId: user.id,
      slug: item.slug,
      brand: this.ACTIVE_BRAND,
      type
    };

    return post(this.FAVORITES_URL, payload);
  };

  addAllFavorites = async (user, items, type) => {
    const payload = items.map(item => {
      return {
        userId: user.id,
        slug: item.slug,
        brand: this.ACTIVE_BRAND,
        type
      };
    });

    const url = `${this.FAVORITES_URL}/all`;

    return post(url, payload);
  };

  getFavorite = async (user, item, type) => {
    const brand = this.ACTIVE_BRAND;
    const userId = user.id;
    const slug = item.slug;

    const url = `${this.FAVORITES_URL}/${brand}/${type}/${userId}/${slug}`;

    return get(url);
  };

  getFavoritesFromRivana = async (user, type) => {
    const brand = this.ACTIVE_BRAND;
    const userId = user.id;

    const url = `${this.FAVORITES_URL}/${brand}/${type}/${userId}`;

    return get(url);
  };

  // Fetch Main Ingredient taxonomies from API for recipe card
  fetchMainIngredientTaxonomy = async () => {
    const brand = this.ACTIVE_BRAND;
    const url = `${
      this.WP_URL
    }/${brand}/wp-json/wp/v2/main_ingredient?_fields[]=slug&_fields[]=id&_fields[]=name&per_page=50`;
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      return response.json();
    } catch (err) {
      console.error(err);
    }
  };

  getFavoritesFromWp = async (slugs, type) => {
    const slug = slugs.join(',');
    const brand = this.ACTIVE_BRAND;

    // const url = `${
    //   this.WP_URL
    // }/american-beauty/wp-json/wp/v2/recipes?slug=${slug}&_fields[]=${fields}`;

    const url = `${
      this.WP_URL
    }/${brand}/wp-json/wp/v2/${type}?per_page=100&slug=${slug}`;
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json' //,
          //Authorization: `Bearer ${localStorage.getItem('user-wp-token')}`
        }
        //mode: 'cors',
      });

      return response.json();
    } catch (err) {
      console.error(err);
    }
  };

  getFavoritesForUser = async (user, type) => {
    const rivianaFavorites = await this.getFavoritesFromRivana(user, type);
    if (rivianaFavorites && rivianaFavorites.length) {
      const slugs = rivianaFavorites.map(i => i.slug);
      if (slugs.length) {
        return this.getFavoritesFromWp(slugs, type);
      }
    }

    return [];
  };
}
