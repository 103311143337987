import React from 'react';
import styled from 'styled-components';
import ComponentStyles from '../brand-styles/components/FlyoutFavorites.styles';
import { UiStateConsumer } from '../context/UiStateContext';
import { breakpoints, colors } from '../style-utilities/variables';
import RegularButton, { Button } from './RegularButton';

const StyledFlyoutFavorites = styled.div`
  background: white;
  box-shadow: 1px 2px 10px #aaa;
  display: none;
  position: absolute;
  right: -69px;
  top: 38px;
  z-index: 5;
  text-align: center;
  padding: 10px 20px 18px;
  /* overflow-x: hidden; */
  h3 {
    font-weight: bold;
    font-size: 22px;
    color: ${colors.primary};
  }
  p {
    font-size: 18px;
  }
  &.show {
    display: block;
    .button-container {
      display: flex;
      justify-content: center;
      justify-content: space-evenly;
      margin-top: 14px;
    }
    button {
      padding: 12px 30px;
      width: 145px;
      margin: 0 auto;
      display: block;
      border: none;
      background: ${colors.primary};
      color: white;
      margin-bottom: 14px;
      border: 1px solid ${colors.primary};
      margin-right: 8px;
      cursor: pointer;
      &:hover {
        opacity: 0.8;
        transition: opacity 0.3s ease-out;
      }
    }
  }

  .caret {
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid white;
    height: 0;
    position: absolute;
    right: 68px;
    top: -12px;
    width: 0;
  }

  @media (min-width: 900px) {
    right: -46px;
    top: 42px;
    .caret {
      right: 45px;
    }
  }

  /* Dynamic brand styles */
  ${ComponentStyles}
`;

const FlyoutFavorites = ({ children, styles, siteTitle }) => {
  return (
    <UiStateConsumer>
      {context => {
        return (
          <StyledFlyoutFavorites
            className={context.flyoutFavoritesOpen ? 'show' : ''}
            {...styles}
          >
            <div className="caret" />

            <h3 className="flyoutHeading">Save &amp; Favorite Recipes</h3>
            {siteTitle === 'carolina' ? (
              <p>Sign up to save recipes, create shopping lists, and more!</p>
            ) : (
              <>
                <p>Save, print, and email custom shopping lists.</p>
                <p>Save and favorite recipes.</p>
                <p>Rate and review recipes.</p>
              </>
            )}

            <div className="button-container">{children}</div>
          </StyledFlyoutFavorites>
        );
      }}
    </UiStateConsumer>
  );
};

export default FlyoutFavorites;
