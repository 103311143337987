import React, { Component } from 'react';
import styled from 'styled-components';
import { graphql, StaticQuery } from 'gatsby';

import ComponentStyles from '../../brand-styles/components/Header/NavigationMenu.styles';
import classNames from 'classnames';
import { UiStateConsumer } from '../../context/UiStateContext';
import { UserStateConsumer } from '../../context/UserStateContext';
import FavoritesLink from './FavoritesLink';
import MainNav from './MainNav';
import WhereToBuyNav from '../Header/WhereToBuyNav';
import LanguageSelect from './LanguageSelect';
import UserWidget from './UserWidget';
import Search from './Search';
import MainLogo from './MainLogo';
import { breakpoints, colors, fonts } from '../../style-utilities/variables';
import CloseMenuButton from './CloseMenuButton';
import RegularButton from '../RegularButton';
import WhiteButton from '../WhiteButton';
import StyledLink from '../StyledLink';
import SignUpSignInFormMobile from '../Forms/SignUpSignInFormMobile';

//Desktop
const StyledNav = styled.div`
  display: none;
  height: 65px;

  a:not(.where-to-buy-nav a) {
    /* links were not showing in safari */
    /* color: white; */
  }
  ul {
    background: transparent;
  }
  @media (min-width: 900px) {
    ul {
      margin: 0;
      margin-top: 4px;
      padding: 0;
    }
    display: flex;
    align-items: center;
  }
  svg {
    color: white;
  }
  @media (min-width: 900px) {
    .logo {
      max-width: 200px;
    }
  }
  @media (min-width: ${breakpoints.desktop}) {
    .logo {
      max-width: 300px;
    }
  }

  .main-nav-right {
    display: flex;
    margin-left: auto;
    margin-right: 5px;
    align-items: center;
    .fav-div {
      display: flex;
      align-items: center;
      width: 42px;
      height: 42px;
      margin: 0 auto;
      position: relative;
    }
    .favorite-count {
      position: absolute;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      width: 1.5em;
      height: 1.5em;
      right: -16px;
      top: -8px;
    }
  }

  /* Dynamic brand styles */
  ${ComponentStyles}
`;

//Mobile
const StyledMobileNav = styled.div`
  background: #eaeaea;
  height: calc(100vh - 65px);
  width: 100vw;
  left: -100vw;
  top: 65px;
  z-index: 600;
  position: fixed;
  transition: 0.3s left ease-in;
  #log-in {
    font-family: ${fonts.secondary};
  }
  @media (max-width: 400px) {
    max-width: 100vw;
    overflow: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 5em;
  }
  &.menu-open {
    opacity: 1;
    left: 0;
    transition: 0.3s left ease-out;
    padding: 0;

    .where-to-buy-link {
      display: none;
    }
  }
  &.page-container {
    padding-left: 0;
    padding-right: 0;

    .where-to-buy-link {
      display: none;
    }
  }

  .mobileLogo {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 40px 0;

    img {
      margin-bottom: 0;
    }
  }
  .topButtons {
    display: flex;
    /* justify-content: space-evenly; */

    .fav-div {
      display: flex;
      width: 42px;
      height: 42px;
      margin: 0 auto;
      position: relative;
      display: flex;
      align-items: center;
    }
    .favorite-count {
      position: absolute;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      width: 1.5em;
      height: 1.5em;
      right: -17px;
      top: -9px;
    }

    .user-widget {
      margin-left: 20px;
    }
    span,
    svg {
      color: white;
    }
  }
  .products,
  .mobile {
    color: ${colors.primary};
  }
  .language {
    text-align: right;
    button {
      color: ${colors.primary};
    }
  }
  .sign-up {
    text-align: center;
  }

  li {
    list-style: none;
    color: ${colors.primary};
  }
  @media (max-width: 500px) {
    justify-content: left;
  }
  /* This was removed because it created issues with the back button for mobile nav on iphones */
  /* .mobile-nav-auth-wrapper,
  .mobile-nav-wrapper {
    height: calc(100vh - 133px);
    overflow: scroll;
  } */

  /* Dynamic brand styles */
  ${ComponentStyles}
`;

class NavigationMenu extends Component {
  state = {
    viewPort: 'desktop',
    isBrowser: true,
    openMenu: null
  };

  componentDidMount() {
    this.setState({
      isBrowser: typeof window !== 'undefined',
      viewPort: window.innerWidth >= 900 ? 'desktop' : 'mobile'
    });
    if (this.state.isBrowser) {
      window.addEventListener('resize', this.setViewport);
    }
  }

  setViewport = () => {
    this.setState({
      viewPort:
        this.state.isBrowser && window.innerWidth >= 900 ? 'desktop' : 'mobile'
    });
  };

  componentWillUnmount() {
    if (this.state.isBrowser) {
      window.removeEventListener('resize', this.setViewport);
    }
  }
  closeMegaMenu = () => {
    const element = document.getElementById('mobile-menu');
    element.classList.remove('menu-open');
  };

  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            wordpressWpOptionsPage(slug: { eq: "site-options" }) {
              acf {
                language_link
              }
            }
          }
        `}
        render={data => {
          const languageLink = data.wordpressWpOptionsPage.acf.language_link;
          const menuClasses = classNames({
            'menu-open': this.props.openMenu,
            'page-container': true
          });
          return (
            <UserStateConsumer>
              {userStateContext => {
                const user = userStateContext.authenticatedUser;
                return (
                  <UiStateConsumer>
                    {uiStateContext => {
                      return this.state.viewPort === 'desktop' ? (
                        <StyledNav>
                          <div className="logo">
                            <MainLogo
                              siteLogo={this.props.siteLogo}
                              altText={this.props.altText}
                            />
                          </div>
                          <MainNav siteType={this.props.siteType} />
                          <WhereToBuyNav />

                          <div className="main-nav-right">
                            {this.props.siteTitle === 'carolina' ? (
                              <LanguageSelect
                                className="language-desktop"
                                siteTitle={this.props.siteTitle}
                                viewPort={this.state.viewPort}
                                languageLink={languageLink}
                              />
                            ) : null}
                            <div className="user-widget">
                              <UserWidget toggle={this.props.toggle} />
                            </div>

                            <FavoritesLink
                              userStateContext={userStateContext}
                              toggle={this.props.toggle}
                              className="desktop-favs"
                              siteTitle={this.props.brandTitle}
                            />

                            <Search id="nav-search" />
                          </div>
                        </StyledNav>
                      ) : (
                        <StyledMobileNav
                          className={menuClasses}
                          id="mobile-menu"
                        >
                          <div className="topButtons">
                            <div className="user-widget">
                              <UserWidget toggle={this.props.toggle} />
                            </div>
                            <div className="fav-div">
                              <FavoritesLink
                                userStateContext={userStateContext}
                                toggle={this.props.toggle}
                                className="mobile-favs"
                                siteTitle={this.props.brandTitle}
                              />{' '}
                            </div>
                          </div>
                          <div className="close-x">
                            <CloseMenuButton toggle={this.props.toggle} />
                          </div>
                          <div
                            className="mobile-nav-wrapper"
                            style={{
                              display:
                                uiStateContext.showSignUpForm ||
                                uiStateContext.showSignInForm
                                  ? 'none'
                                  : 'block'
                            }}
                          >
                            {this.props.siteTitle === 'carolina' ? (
                              <div className="language">
                                <LanguageSelect
                                  viewPort={this.state.viewPort}
                                  className="language-mobile"
                                  siteTitle={this.props.siteTitle}
                                  languageLink={languageLink}
                                />
                              </div>
                            ) : null}
                            <MainNav
                              className="mobile-nav"
                              toggle={this.props.toggle}
                            />
                            <WhereToBuyNav />

                            <div className="sign-up">
                              <div className="container">
                                {user ? (
                                  <>
                                    <h2>Get Started</h2>
                                    <p>
                                      Save, rate and review recipes, create
                                      shopping lists, and more!
                                    </p>
                                  </>
                                ) : (
                                  <>
                                    {this.props.brandTitle === 'mahatma' ? (
                                      <h2>Sign Up to See More</h2>
                                    ) : (
                                      <h2>Main Benefits of Signing Up</h2>
                                    )}
                                    <p>
                                      Save, rate and review recipes, create
                                      shopping lists, and more!
                                    </p>
                                  </>
                                )}

                                {user ? (
                                  <StyledLink
                                    to={`/user/profile`}
                                    onClick={this.closeMegaMenu}
                                  >
                                    View Profile
                                  </StyledLink>
                                ) : (
                                  <StyledLink
                                    to=""
                                    onClick={uiStateContext.toggleSignUpForm}
                                  >
                                    Create Account
                                  </StyledLink>
                                )}
                              </div>
                            </div>
                            {!user && (
                              <p id="log-in">
                                Already have an account?{' '}
                                <button
                                  type="button"
                                  onClick={uiStateContext.toggleSignInForm}
                                >
                                  Log In
                                </button>
                              </p>
                            )}
                          </div>
                          <div
                            className="mobile-nav-auth-wrapper"
                            style={{
                              display:
                                uiStateContext.showSignUpForm ||
                                uiStateContext.showSignInForm
                                  ? 'block'
                                  : 'none'
                            }}
                          >
                            <SignUpSignInFormMobile
                              heading="Unlock Your Shopping List!"
                              showHeading={false}
                            />
                          </div>
                        </StyledMobileNav>
                      );
                    }}
                  </UiStateConsumer>
                );
              }}
            </UserStateConsumer>
          );
        }}
      />
    );
  }
}

export default NavigationMenu;
