import React, { Component } from 'react';
import { UiStateConsumer } from '../../context/UiStateContext';
import { UserStateConsumer } from '../../context/UserStateContext';
import styled from 'styled-components';
import ComponentStyles from '../../brand-styles/components/Forms/SignUpForm.styles';
import FacebookAuth from './FacebookAuth';
import GoogleAuth from './GoogleAuth';
import RegularButton from '../RegularButton';
import { Field, Formik, Form, ErrorMessage } from 'formik';
import LogInLady from '../../images/womanloggingin.jpg';
import LogInLadyTwo from '../../images/BowlsRecipeCollection-020-V2.jpg';
import { colors } from '../../style-utilities/variables';

const StyledSignupForm = styled.div`
  display: flex;

  .account-warning {
    font-size: 0.8rem;
    padding: 0.8rem;
    background-color: #e4f7ff;
  }

  .img-container {
    flex: 1 55%;

    img {
      height: 100%;
      max-width: 100%;
      object-fit: cover;
    }
  }

  .form {
    label,
    input {
      margin-bottom: 10px;
      width: 100%;
    }
    input {
      border: 1px solid #c2c2c2;
      padding: 12px;
    }
    .error {
      color: red;
      font-size: small;
      margin-bottom: 10px;
    }
    button {
      margin-bottom: 20px;
    }
  }

  .content {
    padding: 2% 5%;
    flex: 1 45%;
  }
  .button-link {
    text-decoration: underline;
    border: none;
    background: transparent;
  }

  .facebook,
  .google {
    padding: 15px 0px 15px 30px;
    text-align: left;
    svg {
      width: 50px;
    }
  }

  @media (max-width: 425px) {
    .facebook, .google {
      padding: 15px 0px 15px 15px;
      width: 77vw;
    }
  }

  /* Dynamic brand styles */
  ${ComponentStyles}
`;
class SignUpForm extends Component {
  render() {
    return (
      <UiStateConsumer>
        {uiStateContext => {
          return (
            <StyledSignupForm>
              <div className="img-container">
                <img
                  src={
                    this.props.siteTitle === 'success'
                      ? LogInLadyTwo
                      : LogInLady
                  }
                  alt="woman using tablet"
                />
              </div>
              <UserStateConsumer>
                {userContext => (
                  <div className="content">
                    <h2>Sign Up</h2>
                    <div className="input-group">
                      <FacebookAuth
                        authenticateUser={user =>
                          userContext.authenticateUser('facebook', user)
                        }
                      />
                    </div>
                    <div className="input-group">
                      <GoogleAuth
                        authenticateUser={user =>
                          userContext.authenticateUser('google', user)
                        }
                      />
                    </div>
                    <span className="or">Or</span>
                    <Formik
                      initialValues={{
                        email: '',
                        password: ''
                      }}
                      validate={values => {
                        let errors = {};
                        const emailPattern = /^([A-Z0-9._%+-]{1,64})@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
                        // This is the proper regex for validation,
                        // but dumping all of the requirements is too big for the page
                        // const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
                        if (!/(?=.*[a-z])/.test(values.password)) {
                          errors.password =
                            'Password must contain at least one (1) lowercase letter.';
                        }
                        if (!/(?=.*[A-Z])/.test(values.password)) {
                          errors.password =
                            'Password must contain at least one (1) uppercase letter.';
                        }
                        if (!/.*[0-9]/.test(values.password)) {
                          errors.password =
                            'Password must contain at least one (1) number.';
                        }
                        if (!/^.{8,255}$/.test(values.password)) {
                          errors.password =
                            'Enter a password that is at least eight (8) characters in length.';
                        }
                        if (!values.email || !emailPattern.test(values.email)) {
                          errors.email = 'Please enter a valid email address.';
                        }
                        return errors;
                      }}
                      onSubmit={(values, { setSubmitting }) => {
                        userContext.createUser('form', values);
                      }}
                    >
                      {({ isSubmitting }) => (
                        <Form className="form">
                          <Field
                            type="email"
                            name="email"
                            placeholder="Email"
                            maxLength={255}
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="error"
                          />
                          <Field
                            type="password"
                            name="password"
                            placeholder="Password"
                            maxLength={255}
                          />
                          <ErrorMessage
                            name="password"
                            component="div"
                            className="error"
                          />
                          <RegularButton type="submit">
                            {userContext.requestPending
                              ? 'Creating...'
                              : 'Create Account'}
                          </RegularButton>
                        </Form>
                      )}
                    </Formik>
                    <p>
                      Already have an account?{' '}
                      <button
                        className="button-link"
                        type="button"
                        onClick={() => {
                          uiStateContext.toggleModal();
                          setTimeout(() => {
                            uiStateContext.toggleModal('login');
                          }, 0);
                        }}
                      >
                        Log In
                      </button>
                    </p>
                    <div className="errors">
                      {userContext.errors.map(error => {
                        if (
                          error.message ===
                          'Username already exists, please enter another username'
                        ) {
                          error.message =
                            'Email already exists. Did you sign up through Facebook or Google?';
                        }

                        return (
                          <div
                            key={`${error.field}-${error.message}`}
                            className="error-message"
                            style={{ color: 'red' }}
                          >
                            {error.message}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </UserStateConsumer>{' '}
            </StyledSignupForm>
          );
        }}
      </UiStateConsumer>
    );
  }
}

export default SignUpForm;
