export default class ForgotPasswordService {
  RIVIANA_FORGOT_PASSWORRD_URL = `${
    process.env.RIVIANA_BACKEND_URI
  }/api/forgotPassword`;
  ACTIVE_BRAND = process.env.ACTIVE_BRAND;
  WP_BASE_URI = process.env.WP_BASE_URI;
  USER_FORGOT_PASSWORD_URL = `${this.WP_BASE_URI}${
    process.env.WP_USER_PROFILE_FORGOTPASSWORD_ENDPOINT
  }`;
  USER_RESET_PASSWORD_URL = `${this.WP_BASE_URI}${
    process.env.WP_USER_PROFILE_RESETPASSWORD_ENDPOINT
  }`;

  // Check if user is in the system to be able to reset their password
  checkUser = async email => {
    const response = await fetch(this.USER_FORGOT_PASSWORD_URL, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email
      })
    });
    return response.json();
  };

  resetPassword = async user => {
    const response = await fetch(this.USER_RESET_PASSWORD_URL, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(user)
    });
    return response.json();
  };

  sendForgotPasswordEmail = async responseJson => {
    try {
      const response = await fetch(this.RIVIANA_FORGOT_PASSWORRD_URL, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: responseJson.user_email,
          userId: responseJson.user_id,
          brand: this.ACTIVE_BRAND
        })
      });
      return response.json();
    } catch (err) {
      console.log(err);
    }
  };

  getForgotPassword = async id => {
    const url = `${this.RIVIANA_FORGOT_PASSWORRD_URL}/${id}`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      return response.json();
    } catch (err) {
      console.log(err);
    }
  };

  cleanupForgotPassword = async id => {
    const url = `${this.RIVIANA_FORGOT_PASSWORRD_URL}/${id}`;

    try {
      const response = await fetch(url, {
        method: 'DELETE',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      return response.json();
    } catch (err) {
      console.log(err);
    }
  };
}
