import React from 'react';
import styled from 'styled-components';

import { breakpoints } from '../../style-utilities/variables';
import ComponentStyles from '../../brand-styles/components/Footer/FooterGradient.styles';

const Div = styled.div`
display: block;   
margin: 0 auto;
height: 10px;
background: -webkit-linear-gradient(left, #93824d 1%,#fdd88e 50%,#93824d 100%);
width: 100%;

  /* Dynamic brand styles */
  ${ComponentStyles} 
`;

const FooterGradient = ({ siteLogo, socialLinks }) => {
  return (
    <Div>
    </Div>
  )
};

export default FooterGradient;
