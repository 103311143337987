import React from 'react';
import styled from 'styled-components';
import ComponentStyles from '../brand-styles/components/WhiteButton.styles';
import { colors } from '../style-utilities/variables';

export const Button = styled.button`
  color: ${colors.primary};
  border: 1px solid ${colors.primary};;
  padding: 12px 30px;
  margin-top: 12px;
  font-size: 20px;
  background: white;
  text-decoration: none;
  display: inline-block;
  outline: none;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  :hover {
    background-color: #dfdfdf;
    transition: background-color 0.3s ease-out;
  }
  /* &:hover {
    color: white;
    background-color: ${colors.primary};
    transition: background-color 0.3s ease-out;
  } */

  /* Dynamic brand styles */
  ${ComponentStyles}
`;

const RegularButton = ({ link, children, ...remainProps }) => {
  return (
    <Button className="white-button" {...remainProps}>
      {children}
    </Button>
  );
};

export default RegularButton;
