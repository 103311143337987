/**
 * Placeholder Variables
 * Change values to use in brand styling
 */

// colors
export const colors = {
  primary: '#BE0d34',
  primaryHover: 'rgba(190, 13, 52, 0.7)',
  secondary: '#872333',
  accent: '#B7985E',
  bodyText: 'rgb(51, 51, 51)',
  grey: '#D1D4D3',
  darkGrey: '#909090',
  black: '#000',
  white: '#fff',
  whiteHover: 'rgba(255, 255, 255, 0.8)'
};

// breakpoints
export const breakpoints = {
  mobile: '480px',
  mobileMax: '767px',
  tablet: '768px',
  generalDesktop: '900px',
  desktop: '1100px',
  maxContainer: '1400px'
};
export const rosebud = {
  width: '75px',
  height: '73px'
};

//fonts
export const fonts = {
  primary: 'Poppins',
  secondary: 'TradeGothic',
  secondaryBold: 'TradeGothicBold'
};

// brand id
export const brand = 'american-beauty';
