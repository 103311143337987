import React, { Component } from 'react';
import styled from 'styled-components';
import ComponentStyles from '../../brand-styles/components/Forms/GoogleAuth.styles';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';

const StyledGoogleAuth = styled.div`
  .google {
    background: #db3236;
    color: white;
    display: block;
    width: 300px;
    padding: 15px 0px 15px 30px;
    margin: 10px auto;
    border: none;
    text-align: left;
    svg {
      width: 50px !important;
    }
  }
  /* Dynamic brand styles */
  ${ComponentStyles}
`;

class GoogleAuth extends Component {
  state = {
    isBrowser: typeof window !== 'undefined',
    GoogleLogin: null
  };

  componentDidMount() {
    import('react-google-login').then(module => {
      this.setState({
        GoogleLogin: module.GoogleLogin
      });
    });
  }

  render() {
    if (this.state.isBrowser && this.state.GoogleLogin) {
      const { GoogleLogin } = this.state;

      return (
        <StyledGoogleAuth>
          <GoogleLogin
            clientId={process.env.GOOGLE_AUTH_CLIENT_ID}
            buttonText="Sign Up with Google"
            onSuccess={this.props.authenticateUser}
            onFailure={error => console.error(error)}
            render={renderProps => (
              <button onClick={renderProps.onClick} className="google">
                <FontAwesomeIcon icon={faGoogle} /> Continue with Google
              </button>
            )}
          />
        </StyledGoogleAuth>
      );
    } else {
      return <div>Loading Google Auth Interface</div>;
    }
  }
}

export default GoogleAuth;
