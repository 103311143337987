import React, { Component } from 'react';
import styled from 'styled-components';
import ComponentStyles from '../../brand-styles/components/Modals/Modal.styles';
import LoginForm from '../Forms/LoginForm';
import SignUpForm from '../Forms/SignUpForm';
import ForgotPasswordForm from '../Forms/ForgotPasswordForm';
import ShoppingListEdit from '../ShoppingList/ShoppingListEdit';
import { colors, breakpoints } from '../../style-utilities/variables';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faTimes } from '@fortawesome/free-solid-svg-icons';

const ModalBackdrop = styled.div`
  background: rgba(0, 0, 0, 0.65);
  display: none;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;

  &.show {
    display: block;
  }

  /* Dynamic brand styles */
  ${ComponentStyles}
`;
const ModalDialog = styled.div`
  background: white;
  left: 50%;
  min-height: auto;
  width: 85%;
  max-width: 700px;
  position: absolute;
  top: 46.5%;
  transform: translate(-50%, -50%);

  @media (max-width: ${breakpoints.mobileMax}) {
    height: 80vh;
    z-index: 12000;
  }

  .close-button {
    font-size: 32px;
    position: absolute;
    right: 7px;
    border: none;
    background: transparent;
  }
`;

const Modal = ({
  modalOpen,
  modalType,
  toggleModal,
  modalOptions,
  siteTitle
}) => {
  const modalTypeSwitch = () => {
    switch (modalType) {
      case 'login':
        return <LoginForm showImage siteTitle={siteTitle} />;
      case 'signUp':
        return <SignUpForm siteTitle={siteTitle} />;
      case 'forgotpassword':
        return <ForgotPasswordForm />;
      case 'shoppinglistedit':
        return (
          <ShoppingListEdit
            item={modalOptions && modalOptions.data ? modalOptions.data : {}}
            close={toggleModal}
          />
        );
      default:
        return <div>modalType not provided! Provide</div>;
    }
  };

  return (
    <ModalBackdrop className={modalOpen ? 'show' : ''}>
      <ModalDialog className="modal-dialog">
        <button className="close-button" onClick={toggleModal}>
          <FontAwesomeIcon
            className="close"
            icon={faTimes}
            style={{ width: '20px', height: '20px' }}
          />
        </button>
        {modalTypeSwitch()}
      </ModalDialog>
    </ModalBackdrop>
  );
};

export default Modal;
