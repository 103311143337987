import { css } from 'styled-components';
import { colors, breakpoints, fonts } from '../../../style-utilities/variables';
import search from '../../../images/search-bg.jpg';

// floating heart

export default css`
  top: 80px;
  .user-widget {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    margin-left: 5px;
    min-width: 50%;
    max-width: 275px;
    border-right: 1px solid ${colors.grey};
  }

  .topButtons {
    justify-content: space-between;
    align-items: center;
    .fav-div {
      width: 2em;
      height: 2em;
      margin: 0 auto;
      position: relative;
    }
    .favorite-count {
      position: absolute;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      justify-content: center;
      align-items: center;
      background: ${colors.primary};
      color: #fff;
      border-radius: 50%;
      width: 1.5em;
      height: 1.5em;
      right: -17px;
      top: -9px;
    }
  }
  .main-nav-right {
    .fav-div {
      width: 2em;
      height: 2em;
      margin: 0 auto;
      position: relative;
    }
    .favorite-count {
      position: absolute;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      justify-content: center;
      align-items: center;
      background: ${colors.secondary};
      color: #fff;
      border-radius: 50%;
      width: 1.5em;
      height: 1.5em;
      right: -16px;
      top: -8px;
    }
  }

  @media (min-width: 900px) {
    padding: 0 20px;
  }
  @media (max-width: 899px) {
    #mobile-menu {
      background: #eaeaea;
      overflow: scroll;
      height: 100vh;
    }
    .main-nav-right {
      margin-right: 5em;
    }
    z-index: 300;
    .topButtons {
      background: ${colors.secondary};
      justify-content: left;
      z-index: 0;
      .user-widget {
        padding: 10px 0;
      }
    }
    .close-x {
      display: none;
    }
    .mobile-nav img {
      width: 100px;
      top: 10px;
    }
  }

  .sign-up {
    background: rgba(204, 204, 204, 0.5);
    background-image: url(${search});
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0;
    padding: 0;
    font-family: 'GothamMedium';
    color: ${colors.secondary};
    .container {
      background: rgba(204, 204, 204, 0.5);
      padding: 10px 14px 20px;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      h2 {
        color: ${colors.secondary};
        font-family: ${fonts.primary};
      }
      a {
        font-family: ${fonts.secondary};
      }
    }
  }

  #log-in {
    color: ${colors.secondary};
    padding: 10px 20px;
    button {
      text-decoration: underline;
      color: ${colors.secondary};
      border: none;
      background-color: transparent;
    }
  }

  @media (max-width: 450px) {
    #log-in {
      text-align: center;
      margin-bottom: 3em;
    }
  }
`;
