import { css } from 'styled-components';
import background from '../../../images/footer-texture.png';
import { breakpoints } from '../../../style-utilities/variables';
export default css`
  background-image: url(${background});
  background-repeat: no-repeat;
  background-size: cover;
  ul {
    li:nth-child(3) {
      display: none;
    }
    a:hover {
      opacity: 0.7;
    }
  }
  .BottomFootContainer {
    max-width: none;

    @media (max-width: ${breakpoints.generalDesktop}) {
      flex-direction: column;
    }
  }
`;
