import React from 'react';
import styled from 'styled-components';

//Variables
import { breakpoints, colors } from '../../style-utilities/variables';

//Components
import FooterLogo from './FooterLogo';
import FooterNav from './FooterNav';
import SocialMedia from './SocialMedia';

//Cascading styles for brand brands
import ComponentStyles from '../../brand-styles/components/Footer/TopFooter.styles';

const Div = styled.div`
  display: block;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  background: ${colors.primary};
  a {
    color: white;
    text-decoration: none;
  }

  @media (min-width: ${breakpoints.mobileMax}) {
    .TopFootContainer {
      max-width: ${breakpoints.maxContainer};
      margin: 0 auto;
      position: relative;
      display: flex;
      align-items: center;
    }
    .logo {
      flex-grow: 0;
    }
    .links {
      flex-grow: 1;
    }
    .social {
      flex-grow: 3;
    }
  }
  @media (min-width: 1440px) {
    .links {
      flex-grow: 1;
    }
    .social {
      flex-grow: 5;
    }
  }

  /* Dynamic brand styles */
  ${ComponentStyles}
`;

const TopFooter = ({ siteLogo, altText, socialLinks, siteTitle }) => {
  return (
    <Div>
      {siteTitle === 'mahatma' ? <div className="mahatmaFooterBar" /> : ''}
      <div className="TopFootContainer">
        <div className="logo">
          <FooterLogo siteLogo={siteLogo} altText={altText} />
        </div>
        <div className="links">
          <FooterNav siteTitle={siteTitle} />
        </div>
        <div className="social">
          <SocialMedia socialLinks={socialLinks} />
        </div>
      </div>
    </Div>
  );
};

export default TopFooter;
