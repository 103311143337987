// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-california-supply-chain-transparency-law-jsx": () => import("./../../../src/pages/california-supply-chain-transparency-law.jsx" /* webpackChunkName: "component---src-pages-california-supply-chain-transparency-law-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-cookie-jsx": () => import("./../../../src/pages/cookie.jsx" /* webpackChunkName: "component---src-pages-cookie-jsx" */),
  "component---src-pages-cooking-jsx": () => import("./../../../src/pages/cooking.jsx" /* webpackChunkName: "component---src-pages-cooking-jsx" */),
  "component---src-pages-faq-categories-jsx": () => import("./../../../src/pages/faq-categories.jsx" /* webpackChunkName: "component---src-pages-faq-categories-jsx" */),
  "component---src-pages-faqs-jsx": () => import("./../../../src/pages/faqs.jsx" /* webpackChunkName: "component---src-pages-faqs-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-preview-recipes-jsx": () => import("./../../../src/pages/preview/recipes.jsx" /* webpackChunkName: "component---src-pages-preview-recipes-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-product-detail-jsx": () => import("./../../../src/pages/product-detail.jsx" /* webpackChunkName: "component---src-pages-product-detail-jsx" */),
  "component---src-pages-products-jsx": () => import("./../../../src/pages/products.jsx" /* webpackChunkName: "component---src-pages-products-jsx" */),
  "component---src-pages-recipe-categories-jsx": () => import("./../../../src/pages/recipe-categories.jsx" /* webpackChunkName: "component---src-pages-recipe-categories-jsx" */),
  "component---src-pages-recipe-collection-detail-jsx": () => import("./../../../src/pages/recipe-collection-detail.jsx" /* webpackChunkName: "component---src-pages-recipe-collection-detail-jsx" */),
  "component---src-pages-recipe-collections-jsx": () => import("./../../../src/pages/recipe-collections.jsx" /* webpackChunkName: "component---src-pages-recipe-collections-jsx" */),
  "component---src-pages-recipe-detail-jsx": () => import("./../../../src/pages/recipe-detail.jsx" /* webpackChunkName: "component---src-pages-recipe-detail-jsx" */),
  "component---src-pages-recipe-search-jsx": () => import("./../../../src/pages/recipe-search.jsx" /* webpackChunkName: "component---src-pages-recipe-search-jsx" */),
  "component---src-pages-reset-password-jsx": () => import("./../../../src/pages/reset-password.jsx" /* webpackChunkName: "component---src-pages-reset-password-jsx" */),
  "component---src-pages-search-results-jsx": () => import("./../../../src/pages/search-results.jsx" /* webpackChunkName: "component---src-pages-search-results-jsx" */),
  "component---src-pages-terms-jsx": () => import("./../../../src/pages/terms.jsx" /* webpackChunkName: "component---src-pages-terms-jsx" */),
  "component---src-pages-terms-of-use-jsx": () => import("./../../../src/pages/terms-of-use.jsx" /* webpackChunkName: "component---src-pages-terms-of-use-jsx" */),
  "component---src-pages-user-jsx": () => import("./../../../src/pages/user.jsx" /* webpackChunkName: "component---src-pages-user-jsx" */),
  "component---src-pages-where-to-buy-jsx": () => import("./../../../src/pages/where-to-buy.jsx" /* webpackChunkName: "component---src-pages-where-to-buy-jsx" */),
  "component---src-templates-faq-category-jsx": () => import("./../../../src/templates/faq-category.jsx" /* webpackChunkName: "component---src-templates-faq-category-jsx" */),
  "component---src-templates-product-category-jsx": () => import("./../../../src/templates/product-category.jsx" /* webpackChunkName: "component---src-templates-product-category-jsx" */),
  "component---src-templates-product-detail-jsx": () => import("./../../../src/templates/product-detail.jsx" /* webpackChunkName: "component---src-templates-product-detail-jsx" */),
  "component---src-templates-recipe-collection-detail-jsx": () => import("./../../../src/templates/recipe-collection-detail.jsx" /* webpackChunkName: "component---src-templates-recipe-collection-detail-jsx" */),
  "component---src-templates-recipe-detail-jsx": () => import("./../../../src/templates/recipe-detail.jsx" /* webpackChunkName: "component---src-templates-recipe-detail-jsx" */)
}

