import React, { Component } from 'react';
import styled from 'styled-components';
import ComponentStyles from '../../brand-styles/components/Header/SearchForm.styles';
import classNames from 'classnames';
import SearchState, { SearchStateConsumer } from '../../context/SearchContext';

import { breakpoints } from '../../style-utilities/variables';

const StyledSearchForm = styled.form`
  z-index: 200;
  align-items: center;
  background: white;
  display: flex;
  height: 0;
  justify-content: space-between;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 65px;
  width: 100vw;
  background: #fff;
  &.search-open {
    height: auto;
    display: block;
    width: 100%;
    border-top: 1px solid gray;
    border-bottom: 1px solid gray;
  }
  input {
    height: 40px;
    width: 100%;
    padding: 20px 10px 10px;
    font-size: 18px;
    font-style: italic;
    border: none;
    outline: none;
  }

  /* Dynamic brand styles */
  ${ComponentStyles}
`;

const SearchForm = props => {
  const { searchIsOpen, searchToggle, setRef } = props;
  const formClass = classNames({
    'search-open': searchIsOpen
  });

  return (
    <SearchStateConsumer>
      {searchContext => {
        return (
          <StyledSearchForm
            onSubmit={event => {
              event.preventDefault();
              searchContext.searchSubmit();
              // searchContext.searchSubmit(searchContext.searchTerm);
              setTimeout(() => searchToggle(), 1000);
              event.target.reset();
            }}
            className={formClass}
          >
            <input
              className={'searchInput'}
              ref={setRef}
              value={searchContext.searchTerm}
              onChange={event => {
                searchContext.updateSearchTerm(event.target.value);
              }}
              type="text"
              placeholder={
                props.siteTitle && props.siteTitle.includes('-es')
                  ? '¿Qué le gustaría buscar?'
                  : 'What would you like to search for?'
              }
            />
            {searchContext.requestPending && (
              <span className="loader">searching...</span>
            )}
          </StyledSearchForm>
        );
      }}
    </SearchStateConsumer>
  );
};

export default SearchForm;
