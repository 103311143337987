import 'isomorphic-fetch';
import 'url-search-params-polyfill';
import React from 'react';
import { Helmet } from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import UserState, { UserStateConsumer } from '../context/UserStateContext';
import UiState, { UiStateConsumer } from '../context/UiStateContext';
import ShoppingListState from '../context/ShoppingListContext';
import ReviewsState from '../context/ReviewsContext';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import 'normalize.css';
import './layout.css';
import styled from 'styled-components';
import GlobalStyles from '../brand-styles/global.styles.js';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import Modal from '../components/Modals/Modal';
import SearchState, { SearchStateConsumer } from '../context/SearchContext';

if (!String.prototype.startsWith) {
  Object.defineProperty(String.prototype, 'startsWith', {
    value: function(search, pos) {
      pos = !pos || pos < 0 ? 0 : +pos;
      return this.substring(pos, pos + search.length) === search;
    }
  });
}

// check for browser before importing module
// because is references global 'window'
if (typeof window !== 'undefined') {
  let WebFont;
  import('webfontloader').then(module => {
    WebFont = module;

    WebFont.load({
      google: {
        families: ['Poppins:300,400,500,600', 'sans-serif']
      }
    });
  });
}

const StyledLayout = styled.div`
  ${GlobalStyles}
`;

const Layout = ({ children }) => {
  return (
    <StaticQuery
      query={graphql`
        query SiteMetaDataQuery {
          site {
            siteMetadata {
              title
            }
          }
          wordpressWpOptionsPage(slug: { eq: "site-options" }) {
            acf {
              site_logo {
                source_url
                alt_text
              }
              site_slug
              site_type
              homepage_carousel_type
              facebook_url
              instagram_url
              youtube_url
              twitter_url
              pinterest_url
            }
          }
        }
      `}
      render={data => {
        const {
          site_type,
          site_slug,
          homepage_carousel_type,
          facebook_url,
          instagram_url,
          youtube_url,
          twitter_url,
          pinterest_url
        } = data.wordpressWpOptionsPage.acf;

        const site_logo = data.wordpressWpOptionsPage.acf.site_logo.source_url;
        const alt_text = data.wordpressWpOptionsPage.acf.site_logo.alt_text;

        const socialLinks = {
          facebook: facebook_url,
          instagram: instagram_url,
          youtube: youtube_url,
          twitter: twitter_url,
          pinterest: pinterest_url
        };
        return (
          // global user state context
          <UserState>
            {/* global ui state context */}
            <UiState>
              {/* global search state */}
              <SearchState>
                {/* global shopping list state */}
                <ShoppingListState>
                  {}
                  <ReviewsState>
                    {}
                    <StyledLayout>
                      <Helmet
                        title={process.env.SITE_TITLE}
                        meta={[
                          { name: 'description', content: '' },
                          { name: 'keywords', content: '' },

                          {
                            name: 'ps-key',
                            content: '3057-5bf32764c876420012417e17'
                          },
                          { name: 'ps-country', content: 'US' },
                          { name: 'ps-language', content: 'en' }
                        ]}
                        link={[
                          {
                            rel: 'stylesheet',
                            href:
                              'https://cdnjs.cloudflare.com/ajax/libs/Swiper/4.4.1/css/swiper.css'
                          },
                          {
                            rel: 'stylesheet',
                            href:
                              'https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.3.15/slick.css'
                          }
                        ]}
                      >
                        <html lang="en" />
                      </Helmet>
                      <UserStateConsumer>
                        {userContext => {
                          return (
                            <Header
                              siteTitle={site_slug}
                              siteLogo={site_logo}
                              altText={alt_text}
                              siteType={site_type}
                              userContext={userContext}
                              brandTitle={site_slug}
                            />
                          );
                        }}
                      </UserStateConsumer>
                      <UserStateConsumer>
                        {userContext => {
                          return (
                            <UiStateConsumer>
                              {context => {
                                return (
                                  <SearchStateConsumer>
                                    {searchContext => {
                                      return (
                                        <div onClick={context.closeFlyout}>
                                          <div>
                                            {/* iterate children and add siteType prop to each one */}
                                            {React.Children.map(
                                              children,
                                              child =>
                                                React.cloneElement(child, {
                                                  siteLogo: site_logo,
                                                  siteType: site_type,
                                                  carouselType: homepage_carousel_type,
                                                  context: context,
                                                  userContext: userContext,
                                                  siteTitle: site_slug,
                                                  searchContext: searchContext
                                                })
                                            )}
                                          </div>
                                          <Footer
                                            siteLogo={site_logo}
                                            altText={alt_text}
                                            socialLinks={socialLinks}
                                            siteTitle={site_slug}
                                          />
                                          <Modal
                                            modalOpen={context.modalOpen}
                                            toggleModal={context.toggleModal}
                                            modalType={context.modalType}
                                            modalOptions={context.modalOptions}
                                            siteTitle={site_slug}
                                          />
                                        </div>
                                      );
                                    }}
                                  </SearchStateConsumer>
                                );
                              }}
                            </UiStateConsumer>
                          );
                        }}
                      </UserStateConsumer>
                    </StyledLayout>
                  </ReviewsState>
                </ShoppingListState>
              </SearchState>
            </UiState>
          </UserState>
        );
      }}
    />
  );
};

export default Layout;
