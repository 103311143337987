import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import ComponentStyles from '../../brand-styles/components/Header/WhereToBuyNav.styles';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { breakpoints, colors } from '../../style-utilities/variables';

const Button = styled.div`
  .where-to-buy-link {
    cursor: pointer;
    text-decoration: none;
    color: #be0d34;
    background: white;
    padding: 12px 30px 8px;
    text-align: center;
    :hover {
      opacity: 0.6;
    }
  }
  .LocationIcon {
    margin: 0 7px 0 0;
    color: #be0d34;
  }
  @media (max-width: 899px) {
    .where-to-buy-link {
      display: block;
      margin: auto;
      background: transparent;
    }
  }

  /* Dynamic brand styles */
  ${ComponentStyles}
`;

const WhereToBuy = () => {
  return (
    <Button className="where-to-buy-nav">
      <Link to="/where-to-buy" className="where-to-buy-link">
        <FontAwesomeIcon
          style={{ width: '16px', height: '16px' }}
          className="LocationIcon"
          icon={faMapMarkerAlt}
          transform="shrink-2"
        />
        Where to Buy
      </Link>
    </Button>
  );
};

export default WhereToBuy;
