import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import ComponentStyles from '../../brand-styles/components/Footer/SocialMedia.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookF,
  faInstagram,
  faPinterest,
  faTwitter,
  faYoutube
} from '@fortawesome/free-brands-svg-icons';
import { breakpoints, colors } from '../../style-utilities/variables';

const StyledSocial = styled.ul`
  padding: 10px 0 0 0;
  margin: 0;
  display: flex;
  justify-content: center;
  li {
    display: inline-block;
    text-decoration: none;
  }
  li:hover {
    opacity: 0.6;
  }
  @media (min-width: ${breakpoints.tablet}) {
    justify-content: flex-end;
  }

  /* Dynamic Brand Styles */
  ${ComponentStyles}
`;

const SocialMedia = ({ socialLinks }) => {
  return (
    <StyledSocial>
      <li className="facebookLink">
        <a
          href={socialLinks.facebook}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon
            icon={faFacebookF}
            size="2x"
            style={{ width: '20px', height: '32px' }}
          />
        </a>
      </li>
      <li className="pinterestLink">
        <a
          href={socialLinks.pinterest}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon
            icon={faPinterest}
            size="2x"
            style={{ width: '32px', height: '32px' }}
          />
        </a>
      </li>
      <li className="instagramLink">
        <a
          href={socialLinks.instagram}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon
            icon={faInstagram}
            size="2x"
            style={{ width: '32px', height: '32px' }}
          />
        </a>
      </li>
      <li className="twitterLink">
        <a href={socialLinks.twitter} target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon
            icon={faTwitter}
            size="2x"
            style={{ width: '32px', height: '32px' }}
          />
        </a>
      </li>
      <li className="youTubeLink">
        <a href={socialLinks.youtube} target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon
            icon={faYoutube}
            size="2x"
            style={{ width: '32px', height: '32px' }}
          />
        </a>
      </li>
    </StyledSocial>
  );
};

export default SocialMedia;
