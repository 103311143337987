import React, { Component } from 'react';
import styled from 'styled-components';
import ComponentStyles from '../../brand-styles/components/Header/MegaMenu.styles';
import { colors, breakpoints, fonts } from '../../style-utilities/variables';
import classNames from 'classnames';

import DesktopProducts from './DesktopProducts';
import DesktopRecipes from './DesktopRecipes';
import MobileProducts from './MobileProducts';
import MobileRecipes from './MobileRecipes';

const StyledDesktop = styled.div`
  z-index: 200;
  align-items: center;
  height: 0;
  display: none;
  justify-content: space-between;
  left: 0;
  width: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  &.mega-open {
    height: auto;
    display: block;
    background: rgba(0, 0, 0, 0.5);
    color: ${colors.black};
    width: 100%;
    top: 65px;
    div:first-child {
      background: white;
    }
    a {
      color: ${colors.primary};
      cursor: pointer;
      outline: none;
      font-weight: normal;
    }
    .styled-link {
      text-align: center;
      padding: 12px 30px;
      font-size: 16px;
    }
    ul,
    li {
      list-style-type: none;
      list-style-position: inside;
      margin: 0;
    }
    li {
      padding-bottom: 14px;
    }
  }
  /* Dynamic brand styles */
  ${ComponentStyles}
`;

const StyledMobile = styled.div`
  width: 100vw;
  right: -100vw;
  visibility: hidden;
  height: 100vh;
  z-index: 800;
  top: 0;
  position: absolute;
  opacity: 0;
  transition: 0.3s right ease-out, 0.3s visibility linear, 0.3s opacity linear;
  &.mega-open-mobile {
    opacity: 1;
    top: 0;
    right: 0;
    visibility: visible;
    overflow-x: hidden;
    max-width: 100%;
    /* -webkit-overflow-scrolling: touch; */
    z-index: 900;
  }

  #main-title {
    text-align: center;
    color: white;
  }
`;

const Div = styled.div`
  ${StyledDesktop}
  ${StyledMobile}
  /* Dynamic brand styles */
  ${ComponentStyles}
`;

class MegaMenu extends Component {
  state = {
    isBrowser: null
  };

  componentDidMount() {
    this.setState({
      isBrowser: typeof window !== 'undefined'
    });
  }

  render() {
    const {
      siteType,
      isMegaOpen,
      megaToggle,
      clickToggle,
      name,
      openMegaMenu,
      enterMenu,
      leaveMenu,
      mainNavToggle,
      isAndroidDevice
    } = this.props;
    const megaClass = classNames({
      'mega-open': openMegaMenu
    });

    const mobileClass = classNames({
      'mega-open-mobile': isMegaOpen
    });
    return (
      <Div className="mega">
        {this.state.isBrowser && window.innerWidth >= 900 ? (
          <StyledDesktop
            className={megaClass}
            onMouseEnter={
              name === 'Products'
                ? () => enterMenu('Products')
                : () => enterMenu('Recipes')
            }
            onMouseLeave={
              name === 'Products'
                ? () => leaveMenu('Products')
                : () => leaveMenu('Recipes')
            }
          >
            {name === 'Products' ? (
              <DesktopProducts megaToggle={clickToggle} siteType={siteType} />
            ) : (
              <DesktopRecipes megaToggle={clickToggle} siteType={siteType} />
            )}
          </StyledDesktop>
        ) : (
          <StyledMobile className={mobileClass} id="menu-mobile">
            {name === 'Products' ? (
              <MobileProducts
                megaToggle={megaToggle}
                mainNavToggle={mainNavToggle}
                siteType={siteType}
                isAndroidDevice={isAndroidDevice}
              />
            ) : (
              <MobileRecipes
                megaToggle={megaToggle}
                mainNavToggle={mainNavToggle}
                siteType={siteType}
                isAndroidDevice={isAndroidDevice}
              />
            )}
          </StyledMobile>
        )}
      </Div>
    );
  }
}

export default MegaMenu;
