import { css } from 'styled-components';
import { colors } from '../../../style-utilities/variables';

export default css`
  #nav-search-btn:hover {
    background: ${colors.primary};
    color: #fff;
    box-shadow: 0px 0px 0px;
  }
`;
