import React, { Component } from 'react';
import { UserStateConsumer } from './UserStateContext';
import ShoppingListService from '../services/shoppingList.service';
import UserService from '../services/user.service';
// context
export const ShoppingListStateContext = React.createContext();
const ShoppingListStateProvider = ShoppingListStateContext.Provider;
export const ShoppingListStateConsumer = ShoppingListStateContext.Consumer;

class ShoppingListState extends Component {
  constructor(props) {
    super(props);

    this.shoppingListService = new ShoppingListService();
    this.userService = new UserService();

    this.state = {
      shoppingListItems: [],
      toggleItemChecked: id => {
        this.state.shoppingListItems.map((item, itemId) => {
          if (itemId === id) {
            item.checked = true;
          }
        });
      },
      //adds and also updates
      addItems: items => {
        const user = this.userService.getAuthenticatedUser();

        if (user && items && items.length) {
          return this.shoppingListService
            .addShoppingListItems(user, items)
            .then(result => {
              this.state.getItems();
            });
        } else {
          return Promise.resolve();
        }
      },
      getItems: () => {
        const user = this.userService.getAuthenticatedUser();

        if (user) {
          this.shoppingListService.getShoppingListForUser(user).then(result => {
            this.setState({
              shoppingListItems: result || []
            });
          });
        }
      },
      deleteItem: item => {
        const user = this.userService.getAuthenticatedUser();

        if (user && item && item.name) {
          this.shoppingListService
            .deleteShoppingListItem(user, item.name)
            .then(result => {
              this.state.getItems();
            });
        }
      }
    };
  }

  render() {
    return (
      <ShoppingListStateProvider value={this.state}>
        {this.props.children}
      </ShoppingListStateProvider>
    );
  }
}

export default React.forwardRef((props, ref) => (
  <UserStateConsumer>
    {userContext => (
      <ShoppingListState {...props} userContext={userContext} ref={ref} />
    )}
  </UserStateConsumer>
));
