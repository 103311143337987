import React, { Component } from 'react';
import ReactDom from 'react-dom';
import styled from 'styled-components';
import ComponentStyles from '../../brand-styles/components/Header/Search.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { SearchStateConsumer } from '../../context/SearchContext';

import { breakpoints } from '../../style-utilities/variables';
import SearchForm from './SearchForm';

const StyledSearch = styled.div`
  grid-area: Search;
  display: flex;
  align-items: center;
  #nav-search-btn {
    width: 60px;
  }
  /* Dynamic brand styles */
  ${ComponentStyles}
`;

const Button = styled.button`
  width: 50px;
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  color: white;
  cursor: pointer;
  .searchIcon,
  .deleteIcon {
    font-size: 25px;
    margin: auto;
  }

  margin-left: 0.5em;

  @media (max-width: ${breakpoints.tabletLandscape}) {
    display: block;
  }
`;

class Search extends Component {
  constructor(props) {
    super(props);
    this.searchInputRef = React.createRef();
    this.clickBody = null;
  }

  state = {
    searchIsOpen: false
  };

  searchToggle = () => {
    this.setState({ searchIsOpen: !this.state.searchIsOpen });
    this.searchInputRef.current.focus();
  };

  render() {
    return (
      <StyledSearch>
        <Button
          onClick={() => {
            this.searchToggle();
            if (this.props.closeMenuOnSearch) {
              this.props.closeMenuOnSearch();
            }
          }}
          id="nav-search-btn"
        >
          {this.state.searchIsOpen === false ? (
            <FontAwesomeIcon
              className="searchIcon"
              icon={faSearch}
              style={{ width: '50px', height: '25px' }}
            />
          ) : (
            <FontAwesomeIcon className="deleteIcon" icon={faTimes} />
          )}
        </Button>
        <SearchForm
          siteTitle={this.props.siteTitle ? this.props.siteTitle : ''}
          searchIsOpen={this.state.searchIsOpen}
          searchToggle={this.searchToggle}
          setRef={this.searchInputRef}
        />
      </StyledSearch>
    );
  }
}

export default Search;
